import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { CodeSandboxCircleFilled } from "@ant-design/icons";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const AddOnProductModal = ({ onClose, dataProp, fetchData }) => {

  const [form, setForm] = useState({ seriesCategoryId: dataProp ? dataProp.seriesTableId : "", name: "", id: null });
  const [loading, setLoading] = useState(false);
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [addOnProducts, setAddOnProducts] = useState([]);

  useEffect(() => {
    console.log(dataProp,"paro dasta")
  if(dataProp){
    setForm({ ...form, name: dataProp?.name, id: dataProp?.addOnListId });
  }
    getSeriesCategoryData();
  }, []);

  const getSeriesCategoryData = async () => {
    let results = await window.Platform.database.getSeriesCategory();
    setSeriesCategories(results.data);
  };

  const handleSeriesChange = (value) => {
    setForm((prev) => ({ ...prev, seriesCategoryId: value }));
    const selectedSeries = seriesCategories.find((item) => item.seriesTableId === value);
    if (selectedSeries) {
      const newId = selectedSeries.addOnProduct ? selectedSeries.addOnProduct : Math.floor(1000000000 + Math.random() * 9000000000);
      setForm((prev) => ({
        ...prev,
        id: newId
      }));
    }
  };

  const handleSubmit = async () => {
    if (!form.seriesCategoryId || !form.name) {
      window.NotificationUtils.showError("Please Fill All Details");
      return;
    }

    setLoading(true);
    try {
      if(dataProp){

        await window.Platform.database.editAddOnList({ name: form.name, id: form.id });
        window.NotificationUtils.showSuccess("Add-On Product Updated successfully");
  
        setAddOnProducts(prev => [...prev, { name: form.name, id: form.id }]);
      }
      else{
        await window.Platform.database.saveAddOnList({ name: form.name, id: form.id });
        window.NotificationUtils.showSuccess("Add-On Product added successfully");
  
        setAddOnProducts(prev => [...prev, { name: form.name, id: form.id }]);
      }

      setForm({ seriesCategoryId: "", name: "", id: null });
      onClose();
      fetchData();
      setLoading(false);
    } catch (err) {
      window.NotificationUtils.showError(err?.message);
    }
    setLoading(false);
  };

  return (
    <Dialog disableEnforceFocus maxWidth="sm" fullWidth open>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{dataProp ? "Edit Add-On Product List" : "Add Add-On Product List"}</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {loading && <OpaqueLoading />}

        <Stack gap={2} padding={2}>
          <FormControl fullWidth>
            <InputLabel>Select Series</InputLabel>
            <Select
            disabled={dataProp}
              value={form.seriesCategoryId}
              onChange={(e) => handleSeriesChange(e.target.value)}
            >
              {/* {seriesCategories.map((option) => (
                <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                  {option.seriesName}
                </MenuItem>
              ))} */}
              {seriesCategories?.map((option) => (
  <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
    {option.seriesName.charAt(0).toUpperCase() + option.seriesName.slice(1).toLowerCase()}
  </MenuItem>
))}

            </Select>
          </FormControl>
          <TextField
            label="Add-On Product Name"
            value={form.name || ''}
            type="text"
            onChange={(e) => setForm((prev) => ({ ...prev, name: e.target.value }))}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </StyledDialogContent>
    </Dialog>
  );
};

export default AddOnProductModal;
