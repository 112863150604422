import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
  Chip
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const isMobile = window.innerWidth < 900;

const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile ? "100%" : "98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile ? "0px" : "10px"};
  margin-bottom:${isMobile ? "0px" : "10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile && "20px"};
 
//   height: 500px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;


const AddOrderModal = ({ props, onClose1, onClose, remarks, dataProp, id, distributorId, fetchData, status }) => {
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);

  // const [details, setDetails] = useState(data);

  const [roles, setRoles] = useState([])
  const [plantCode, setPlantCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState([])
  // const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
  const [deletedField, setDeletedFields] = useState([])
  const [hasChanges, setHasChanges] = useState(false);
  const [remark, setRemrak] = useState(remarks)
  const [errors, setErrors] = useState({});
  const [productErrors, setProductErrors] = useState({});
  const [form, setForm] = useState({
    ownerId: "" || dataProp?.ownerId,
    orderType: "" || dataProp?.orderType,
    partyType1: "" || "0",
    partyId: "" || dataProp?.partyId,
    discount: "" || dataProp?.Discount,
    transport: "" || dataProp?.Transport,
    email: "" || dataProp?.emailId,
    mobileNo: "" || dataProp?.mobileNo,
    address: "" || dataProp?.Address,
    remark: "" || dataProp?.remark,
    approvalStatus: "" || dataProp?.approvalStatus



  })
  const [employee, setEmployee] = useState([])
  const [assignedEmp, setAssignedEmp] = useState(null)
  const [party, setParty] = useState([])
  const [selectParty, setSelectParty] = useState(null)

  const [product1, setProduct1] = useState([])


  const [board, setBoard] = useState()
  const [medium, setMedium] = useState()
  const [className, setClassName] = useState()
  const [series, setSeries] = useState([])
  const [series1, setSeries1] = useState([])

  const [selectedSeries, setSelectedSeries] = useState(null)

  const [selectDataFilter, setSelectDataFilter] = useState({
    board: '', class: '', medium: '', series: ''
  })
  const [selectDataFilter1, setSelectDataFilter1] = useState({
    board: '', class: '', medium: '', series: ''
  })

  const [disableSeries, setDisableSeries] = useState(false)
  const [disableOther, setDisableOther] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [dataGrid, setDataGrid] = useState([])
  const [deletedIds, setDeletedIds] = useState([])
  const [specimen, setSpecimen] = useState([])
  const [specimen1, setSpecimen1] = useState([])

  const [selectSpecimen, setSelectedSpecimen] = useState(null)
  const [getSeriesCategory, setgetSeriesCategory] = useState()
  const [bookType, setBookType] = useState(null)
  const [seriesAdd, setSeriesAdd] = useState([])

  const [mittplusProductGroups, setMittplusProductGroups] = useState([]);
  const [productGroup, setProductGroup] = useState({});
  const [productType, setProductType] = useState();
  const [renderPartnerForm, setRenderPartnerForm] = useState(true);
  const [partnerProductId, setPartnerProductId] = useState();
  const [subscriptionProductId, setSubscriptionProductId] = useState();
  const [productGroupMittsure, setProductGroupMittsure] = useState();
  const [productGroupMittplus, setProductGroupMittplus] = useState();
  const [productGroups, setProductGroups] = useState();
  const [productGroupId, setProductGroupId] = useState();
  const [renderMittplusForm, setRenderMittplusForm] = useState();
  const [products, setProducts] = useState([]);
  const [productForm, setProductForm] = useState({
    itemId: "",
    productGroup: "",
    qty: "",
    price: "",
    total: "",
    series: ""
  });
  const [dataGridProduct, setDataGridProduct] = useState([]);
  const [transporter, setTransporter] = useState([]);
  const [accessChanges, setAccessChanges] = useState("");


  const getTransporters = async () => {
    try {

      setLoading(true);
      let results = await window.Platform.database.getTransporters({ forDD: true });
      setTransporter(results.data);

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      setAccessChanges(jsonArrayWithId);
      setLoading(false);

    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const [orderType, setOrderType] = useState(0)

  useEffect(() => {

    fetchEmployees()
    getBoard()
    getMedium()
    getClass()

    fetchSpecimen()
    getSeriesCategoryData()

    getMittplusProductGroups();
    getProductGroups();
    getTransporters();

    if (dataProp?.orderType === 'Sales') {
      setOrderType(1);
    } else {
      setOrderType(2);
    }

  }, [])

  useEffect(() => {
    updateDataGrid1()

  }, [selectDataFilter1.medium, selectDataFilter1.class, selectDataFilter1.board, selectDataFilter1.series])

  useEffect(() => {
    if (bookType === 2) {
      updateDataGrid()
    }
    else {
      updateDataGrid2()
    }
  }, [selectDataFilter.medium, selectDataFilter.class, selectDataFilter.board, selectDataFilter.series, form.qty, bookType])
  useEffect(() => {
    if (!status) {
      fetchOrderLineItem(dataProp.orderId)
      getSchool({ ownerId: dataProp.ownerId })
    }
  }, [])

  const productGroupHandler = (data) => {
    setProductForm({
      productGroup: data.id
    });
    setProductGroup(data);
    if (data.name.includes("MittPlus")) {
      setRenderMittplusForm(true);
    } else {
      setRenderMittplusForm(false);
    }
  };

  const productTypeHandler = async (data) => {
    if (data.name.includes("Subscription")) {
      setRenderPartnerForm(false);
    } else {
      setRenderPartnerForm(true);
    }
    setProductType(data);
    let results = await window.Platform.database.getProducts(data);
    setProducts(results.data);
  }

  const productHandler = (data) => {
    setProduct(data);
    const obj = {
      itemId: data.id,
      price: data.landing_cost,
    }
    setProductForm(obj)
  }

  const getMittplusProductGroups = async (data) => {
    let results = await window.Platform.database.getMittplusProductGroups();
    setMittplusProductGroups(results.data);
    const defaultProductGroup = results.data.find((productGroup) => {
      if (productGroup.name.includes("Partner")) {
        return productGroup;
      }
    });

    const subscriptionProductGroup = results.data.find((productGroup) => {
      if (productGroup.name.includes("Subscription")) {
        return productGroup;
      }
    });

    // if (props?.productTypeName.includes("Subscription")) {
    //   setProductGroup(subscriptionProductGroup);
    // } else {
    //   setProductGroup(defaultProductGroup);
    // }
    setPartnerProductId(defaultProductGroup?.id);
    setSubscriptionProductId(subscriptionProductGroup?.id);
  };

  const getProductGroups = async (data) => {
    let results = await window.Platform.database.getProductGroups();
    setProductGroups(results.data);
    results.data.find((productGroup) => {
      if (productGroup.name.includes("MittSure")) {
        setProductGroup(productGroup);
        setProductGroupMittsure(productGroup);
        return productGroup;
      } else {
        setProductGroupMittplus(productGroup);
      }
    });
  };

  const handleDeleteData = async (id) => {
    let res = dataGridProduct?.filter((el) => {
      return el.id != id;
    });
    setDataGridProduct(res);
  };



  const renderContentMittplus = () => {
    return (
      <div style={{ overflowY: "auto" }}>

        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-label">Product</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={product}
              onChange={(e) => productHandler(e.target.value)}
              label="Select Option"
              required
              error={productErrors.itemId ? true : false}
              disabled={productType ? false : true}
            >
              {products?.map((option, optionIndex) => (
                <MenuItem key={option.id} value={option}>
                  {option.product_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Quantity"
              value={productForm?.qty}
              type="text"
              fullWidth
              onChange={(e) => changeHandlerProduct("qty", e.target.value)}
              required
              error={productErrors.qty ? true : false}
              disabled={productType ? false : true}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Price"
              value={productForm?.price}
              type="text"
              fullWidth
              onChange={(e) => changeHandlerProduct("price", e.target.value)}
              required
              error={productErrors.price ? true : false}
              inputProps={{ readOnly: true }}
              InputLabelProps={{
                shrink: productForm?.price || productForm?.price === 0,
              }}
            />
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <TextField
              label="Total"
              value={productForm?.total}
              type="text"
              fullWidth
              onChange={(e) => changeHandlerProduct("total", e.target.value)}
              required
              error={productErrors.total ? true : false}
              inputProps={{ readOnly: true }}
              InputLabelProps={{
                shrink: productForm?.total || productForm?.total === 0,
              }}
            />
          </FormControl>
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          padding={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Button variant="contained" onClick={() => handleAdd(productForm)}>
            Add
          </Button>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <DataGrid
            className="payrollGrid"
            style={{ height: !dataGridProduct.length ? "200px" : "auto" }}
            density="compact"
            rows={dataGridProduct || []}
            columns={getColumns()}
            rowHeight={60}
            disableSelectionOnClick
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        </Stack>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Button
            variant="contained"
            // disabled={!dataGrid.length}
            onClick={ status ? () => onsubmit(form) : () => editProductMittplus(form) }
          >
            {status ? "Create" : "Edit"}
          </Button> */}
        </div>

        <div style={{ width: "55%" }}>
          <ModalHeaderContainer>
            Total Amount: {tabValue === 1 && calculateTotal(dataGridProduct) || 0}
            {/* <TextField label="Total Amount" value={tabValue===1 && calculateTotal(dataGridProduct) || ""} type="text" disabled/> */}
            {status ?
              <Button variant="contained" onClick={() => onsubmit(form)} disabled={!Boolean(dataGridProduct.length)} >
                Place Order
              </Button> :
              <Button variant="contained" onClick={() => editProductMittplus(form)} disabled={!Boolean(dataGridProduct.length)} >
                Update Order
              </Button>
            }</ModalHeaderContainer>
        </div>
      </div>
    );
  };

  const handleAdd = (data) => {
    // const errors = validateFormProduct(data);
    // setProductErrors(errors);

    // if (Object.keys(errors).length === 0) {
    let pushItem;
    if (renderPartnerForm) {
      pushItem = {
        ...data,
        id: dataGridProduct.length + 1,
        productGroup: productGroup.id,
        itemName: product.product_name
      };
    } else {
      pushItem = {
        ...data,
        id: dataGridProduct.length + 1,
        productGroup: productGroup.id,
        itemName: product.product_name
      };
    }

    // }
    setDataGridProduct([...dataGridProduct, pushItem]);
    setProductForm({
      itemId: "",
      productGroup: "",
      qty: "",
      price: "",
      total: "",
      series: ""
    });
    setProductType(null);
    setProduct(null);
    // else {
    //   window.NotificationUtils.showWarning("Please Fill All Data");
    // }
  };

  const editProductMittplus = (data) => {
    let pushItem;
    if (renderPartnerForm) {
      pushItem = {
        ...data,
        productType: partnerProductId.id,
      };
    } else {
      pushItem = {
        ...data,
        productType: subscriptionProductId.id,
      };
    }
    setDataGridProduct([...dataGrid, pushItem]);
    onUpdate(pushItem);
  }

  const onUpdate = async (data) => {
    try {
      setLoading(true);
      const errors = validateForm(form);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        await window.Platform.database.updateMittplusProduct(data);
        window.NotificationUtils.showSuccess("Order updated successfully!");
        setLoading(false);
        onClose();
        fetchData();
      } else {
        setLoading(false);
        window.NotificationUtils.showWarning("Please fill all required fields to proceed");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchOrderLineItem = async (val) => {
    try {

      let sentData = { id: val }
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setDataGridProduct(jsonArrayWithId)
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)

    }
  }


  const fetchSpecimen = async () => {
    let results = await window.Platform.database.getSpecimen();
    setSpecimen(results.data)
    setSpecimen1(results.data)

  }
  const fetchSeries = async () => {
    let results = await window.Platform.database.fetchSeries();
    setSeries(results.data)
    setSeries1(results.data)

  }
  const updateDataGrid = (selectedContainer) => {
    if (!selectDataFilter.board && !selectDataFilter.medium && !selectDataFilter.class && !selectDataFilter.series) {
      setProduct(product1);
    } else {
      //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
      const filteredData = product1?.filter(item => {
        const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
        const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
        const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;
        const seriesMatch = !selectDataFilter.series || item.seriesCategory === selectDataFilter.series;

        // Return true only if the filter conditions are met based on filter presence
        return (
          (!selectDataFilter.board || boardMatch) &&
          (!selectDataFilter.medium || mediumMatch) &&
          (!selectDataFilter.class || classMatch) &&
          (!selectDataFilter.series || seriesMatch)
        );
      });
      setProduct(filteredData);
    }
  };

  const updateDataGrid1 = (selectedContainer) => {
    if (!selectDataFilter1.board && !selectDataFilter1.medium && !selectDataFilter1.class && !selectDataFilter1.series) {
      // If no container is selected, display all data
      setSpecimen(specimen1);
    } else {
      // Filter the data based on the selected container
      //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
      const filteredData = specimen1?.filter(item => {
        const boardMatch = !selectDataFilter1.board || item.boardId === selectDataFilter1.board;
        const mediumMatch = !selectDataFilter1.medium || item.mediumTableId === selectDataFilter1.medium;
        const classMatch = !selectDataFilter1.class || item.classId === selectDataFilter1.class;
        const seriesMatch = !selectDataFilter1.series || item.seriesCategory === selectDataFilter1.series;

        // Return true only if the filter conditions are met based on filter presence
        return (
          (!selectDataFilter1.board || boardMatch) &&
          (!selectDataFilter1.medium || mediumMatch) &&
          (!selectDataFilter1.class || classMatch) &&
          (!selectDataFilter1.series || seriesMatch)
        );
      });
      setSpecimen(filteredData);
    }
  };

  const updateDataGrid2 = (selectedContainer) => {
    if (!selectDataFilter.board && !selectDataFilter.medium && !selectDataFilter.class && !selectDataFilter.series) {
      setProduct(product1);
    } else {
      //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
      const filteredData = series1?.filter(item => {
        const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
        const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
        const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;
        const seriesMatch = !selectDataFilter.series || item.seriesCategory === selectDataFilter.series;

        // Return true only if the filter conditions are met based on filter presence
        return (
          (!selectDataFilter.board || boardMatch) &&
          (!selectDataFilter.medium || mediumMatch) &&
          (!selectDataFilter.class || classMatch) &&
          (!selectDataFilter.series || seriesMatch)
        );
      });
      setSeries(filteredData);
    }
  };

  const changeHandler = (fieldName, value) => {
    let sanitizedValue = value;

    // Sanitize input for mobileNo and discount
    if (fieldName === "discount" || fieldName === "mobileNo") {
      sanitizedValue = value.replace(/\D/g, ""); // Remove non-digit characters
    }
    // Sanitize input for qty
    else if (fieldName === "qty") {
      sanitizedValue = value.replace(/\D/g, "");
    }
    // Handle partyId
    else if (fieldName === "partyId") {
      const school = party.find((item) => item.schoolId === value);
      setForm((prevForm) => ({
        ...prevForm,
        partyId: value,
        transport: school?.transporterId || null
      }));
      return; // Exit early for partyId to avoid further state updates
    }

    // Update state with sanitized value
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }));
  };

  const changeHandlerProduct = (fieldName, value) => {
    if (fieldName === "qty" || fieldName === "total" || fieldName === "price") {
      const newValue = value.replace(/\D/g, "");
      if (fieldName === "qty") {
        setProductForm((prevForm) => ({ ...prevForm, [fieldName]: newValue?.toString(), "total": (Number(newValue) * Number(productForm.price))?.toString() }));
      } else {
        setProductForm((prevForm) => ({ ...prevForm, [fieldName]: newValue?.toString() }));
      }
    } else {
      setProductForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    }
  };

  const getSchool = async (details) => {
    try {
      setLoading(true)
      const results = await window.Platform.database.getSchool(details);
      setParty(results.data)
      setSelectParty(null)
      if (!status) {
        const filteredEmp = results?.data?.filter((data) => data.schoolId === dataProp.partyId)
        setSelectParty(filteredEmp[0])

      }
      setLoading(false)

      // setDetails(results.data[0]);
      // setProduct(results.data)
    }
    catch (err) {
      console.log(err);
      setLoading(false)

    }
  }
  const orderTypeHandler = (data) => {
    if (data === "Sales") {
      setOrderType(1)
      setDataGrid([])
      setDataGridProduct([]);
      changeHandler("orderType", data)

    }
    else {
      setOrderType(2)
      setDataGrid([])
      setDataGridProduct([]);
      changeHandler("orderType", data)
      setProductForm({
        productGroup: productGroupMittsure.id
      });
      setProductGroup(productGroupMittsure);
      setRenderMittplusForm(false);
    }

  }

  const renderContent1 = () => {
    return (
      <div style={{ maxHeight: "510px", height: "505px", overflowY: "auto" }}>


        <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
            getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
            // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              changeHandler("ownerId", value?.id)
              setAssignedEmp(value)

              getSchool({ ownerId: value?.id })

              //  updateDataGrid(value);

              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)



            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              // const matchingOption = employee.find((option) => option.name === value.name);


            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                variant="outlined"
                required
                error={errors.ownerId ? true : false}
              />
            )} />
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel required>Order Type</InputLabel>
            <Select
              labelId={`Product`}
              label="Order Type"
              value={form?.orderType || ""}
              onChange={(e) => orderTypeHandler(e.target.value)}
            >

              <MenuItem value={"Sales"}>
                Sales
              </MenuItem>
              <MenuItem value={"Specimen"}>
                Specimen
              </MenuItem>

            </Select>
          </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
          {/* <FormControl fullWidth>
      <InputLabel id="select-label">Party Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.partyType1}
        onChange={(e)=>changeHandler("partyType1",e.target.value)}
        label="Select Option"
      >
        {/* {partyType?.map((data) => ( */}
          {/* <MenuItem  value={"0"}>
     School
    </MenuItem>
  {/* ))} */}
          {/* </Select> */}
          {/* </FormControl>  */}
          <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.schoolName} (${option?.schoolId})`}
            getOptionLabel={(option) => `${option?.schoolName}`}
            getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            // disabled={loading 
            // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              changeHandler("partyId", value?.schoolId)
              setSelectParty(value)
              //  updateDataGrid(value);

              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)



            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = party.find((option) => option.schoolName === value.schoolName);


            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                variant="outlined"
                required
                error={errors.partyId ? true : false}
              />
            )} />
          <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "100%" }}>
            {/* <InputLabel >Order Status</InputLabel> */}
            {/* <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.approvalStatus}
            onChange={(e) => changeHandler("approvalStatus",e.target.value)}
          >
         
    <MenuItem value={1}>
      Approved
    </MenuItem>
    <MenuItem value={0} >
      Pending
    </MenuItem>
    <MenuItem value={2}>
      Reject
    </MenuItem>

          </Select> */}
            {/* <TextField
              label="Order Status"
              value={"Approved"}
              type="text"
              fullWidth
              onChange={(e) => changeHandler("approvalStatus", e.target.value)}
            // disabled
            /> */}
<FormControl fullWidth variant="outlined" disabled={loading}>
            <InputLabel required>Select Transporter</InputLabel>
            <Select
              value={form.transport || ''}
              required
              error={errors.transport ? true : false}
              onChange={(event) => {
                const selectedValue = event.target.value;
                changeHandler("transport", selectedValue);
              }}
              label="Select Transporter"
            >
              {transporter.length > 0 ? (
                transporter.map((option) => (
                  <MenuItem key={option.transporterId} value={option.transporterId}>
                    {option.transporter_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
              )}
            </Select>
          </FormControl>
          </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>

          <TextField label="Discount" value={form?.discount} type="text" fullWidth onChange={(e) => changeHandler("discount", e.target.value)} />
          {/* <TextField label="Transport" value={form?.transport} type="text" fullWidth onChange={(e)=>changeHandler("transport",e.target.value)}/> */}
          {/* <FormControl fullWidth variant="outlined" disabled={loading}>
            <InputLabel required>Select Transporter</InputLabel>
            <Select
              value={form.transport || ''}
              required
              error={errors.transport ? true : false}
              onChange={(event) => {
                const selectedValue = event.target.value;
                changeHandler("transport", selectedValue);
              }}
              label="Select Transporter"
            >
              {transporter.length > 0 ? (
                transporter.map((option) => (
                  <MenuItem key={option.transporterId} value={option.transporterId}>
                    {option.transporter_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
              )}
            </Select>
          </FormControl> */}
<TextField label="Email" value={form?.email} type="text" fullWidth onChange={(e) => changeHandler("email", e.target.value)} required error={errors.email ? true : false} />
         
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
          {/* <TextField label="Email" value={form?.email} type="text" fullWidth onChange={(e) => changeHandler("email", e.target.value)} required error={errors.email ? true : false} /> */}
          <TextField label="Contact No" value={form?.mobileNo} type="text" fullWidth onChange={(e) => changeHandler("mobileNo", e.target.value)} inputProps={{ maxLength: 10 }}  required error={errors.mobileNo ? true : false} />
          <TextField label="Send Address" value={form?.address} type="text" fullWidth onChange={(e) => changeHandler("address", e.target.value)} required error={errors.address ? true : false} />
         
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
          {/* <TextField label="Send Address" value={form?.address} type="text" fullWidth onChange={(e) => changeHandler("address", e.target.value)} required error={errors.address ? true : false} /> */}
          <TextField label="Remark" multiline value={form?.remark} type="text" fullWidth onChange={(e) => changeHandler("remark", e.target.value)} />

        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>


        </Stack>



      </div>
    )
  }


  const getSeriesCategoryData = async (data) => {
    let results = await window.Platform.database.getSeriesCategory();
    setgetSeriesCategory(results.data)


  }

  const bookTypeHandler = (data) => {
    if (data === 1) {
      setBookType(data)
      fetchSeries()
    }
    else if (data === 2) {
      setBookType(data)
      fetchProduct()

    }
  }

  const renderContent = (params) => {
    return (
      <div>
        {orderType === 1 ?
          <div style={{ overflowY: "auto" }}>
            <Stack direction={"row"} spacing={1} padding={2}>
              <Stack direction={"row"} spacing={1} width={"100%"}>
                <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "40%" }}>

                  <InputLabel id="select-label">Book Type</InputLabel>
                  <Select
                    disabled={disableOther}
                    labelId="select-label"
                    id="select"
                    value={bookType || ""}
                    onChange={(e) => bookTypeHandler(e.target.value)}
                    label="Select Option"
                  >

                    <MenuItem value={1}>
                      Choose from set
                    </MenuItem>
                    <MenuItem value={2}>
                      Choose individual book
                    </MenuItem>

                  </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "40%" }}>
                  <TextField
                    label="Quantity"
                    value={productForm?.qty || ""}
                    type="text"

                    onChange={(e) =>
                      changeHandlerProduct('qty', e.target.value)
                      //  changeHandlerProduct('qty', e.target.value)
                    }
                  />
                </FormControl>

                <Button variant="text" onClick={() => clearData()} >
                  Clear
                </Button>



              </Stack>

            </Stack>

            <div style={bookType === 1 ? { display: 'none' } : { display: 'block' }}>
              <hr style={{ width: '100%' }} />
              <Stack direction={"row"} spacing={2} padding={1.5}>
                <FormControl style={{ width: isMobile ? "100%" : "40%" }}>
                  <InputLabel id="select-label">Filter By Series</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={productForm?.series || ""}
                    onChange={(e) => seriesHandler(e.target.value)}
                    // onChange={(e)=> changeHandlerProduct('series', e.target.value)}
                    label="Select Option"
                  >
                    {getSeriesCategory?.map((option, optionIndex) => (
                      <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                        {option.seriesName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "40%" }}>
                  <InputLabel id={`select-label`}>Filter By Medium</InputLabel>
                  <Select
                    disabled={disableOther}
                    labelId={`Product`}
                    label=" Medium"
                    value={productForm?.medium || ""}
                    onChange={(e) => mediumHandler(e.target.value)}
                  >
                    {medium?.map((option, optionIndex) => (
                      <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
                        {option.mediumName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "40%" }}>

                  <InputLabel id="select-label">Filter By Class</InputLabel>
                  <Select
                    disabled={disableOther}
                    labelId="select-label"
                    id="select"
                    value={productForm?.classId || ""}
                    onChange={(e) => classHandler(e.target.value)}
                    label="Select Option"
                  >
                    {className?.map((option, optionIndex) => (
                      <MenuItem key={option.classId} value={option.classId}>
                        {option.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <hr style={{ width: '100%' }} />
            </div>
            <Stack direction={"row"} spacing={2} padding={1.5}>
              {bookType === 2 ?
                <Autocomplete
                  options={product}
                  // getOptionLabel={}
                  // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
                  getOptionLabel={(option) => `${option?.nameSku}`}
                  getOptionValue={(option) => option?.skuId || ''}
                  style={{ width: "60%" }}
                  value={selectedProduct}
                  disabled={!bookType || bookType === 1 || !productForm?.qty}
                  // disabled={loading 
                  // || !addNewMode || props.isManager
                  // }
                  noOptionsText={loading ? "Loading..." : "No option"}
                  onChange={(event, value) => {
                    setSelectedProduct(value)
                    // changeHandlerProduct('', )
                    //  updateDataGrid(value);

                    // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                    // {
                    //     setDisableTransfer(false)
                    // }
                    // else
                    // setDisableTransfer(true)



                  }}
                  onInputChange={(e, value) => {
                    // Check if the user's input matches any option
                    const matchingOption = employee.find((option) => option.name === value.name);

                    if (!matchingOption) {
                      // If there's no matching option, set the otherValue to null
                      //   setDeliveryCity(null);
                      // setAssignedEmp();
                      return;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // disabled={
                      //   loading 
                      // || !addNewMode || props.isManager || props.isDeleted
                      // }
                      label={"Choose SKU"}
                      required
                      variant="outlined"
                    />
                  )} />
                :

                <Autocomplete
                  options={series}
                  // getOptionLabel={}
                  disabled={!bookType || bookType === 0 || !productForm?.qty}

                  // getOptionLabel={(option) => `${option?.nameSeries} (${option?.seriesId})`}
                  getOptionLabel={(option) => `${option?.nameSeries}`}
                  getOptionValue={(option) => option?.seriesId || ''}
                  style={{ width: "60%" }}
                  value={selectedSeries}
                  // disabled={loading 
                  // || !addNewMode || props.isManager
                  // }
                  noOptionsText={loading ? "Loading..." : "No option"}
                  onChange={(event, value) => {
                    setSelectedSeries(value)
                    // setDisableOther(true)
                    fetchProductById(value?.seriesId)
                    //  updateDataGrid(value);

                    // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                    // {
                    //     setDisableTransfer(false)
                    // }
                    // else
                    // setDisableTransfer(true)



                  }}
                  onInputChange={(e, value) => {
                    // Check if the user's input matches any option
                    const matchingOption = employee.find((option) => option.name === value.name);


                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // disabled={
                      //   loading 
                      // || !addNewMode || props.isManager || props.isDeleted
                      // }
                      label={"Choose By Set"}
                      required
                      variant="outlined"
                    />
                  )} />
              }

              {/* <TextField label="Unit  Price" disabled value={selectedProduct?.unitPrice||""} type="text" fullWidth /> */}

              {/* <TextField label="Total Price" disabled value={selectedProduct?.totalPrice||""} type="text" fullWidth /> */}

              <Button variant="text" onClick={() => additem()} >
                Add
              </Button>
            </Stack>

            <Stack direction={"row"} spacing={2} padding={1}>


              <DataGrid
                className="payrollGrid"
                //   checkboxSelection={true}
                style={{ height: !dataGridProduct.length ? '200px' : 'auto' }}
                density="compact"
                //   checkboxSelection={true}
                rows={dataGridProduct || []}
                columns={getColumns()}
                rowHeight={60}
                // onRowSelectionModelChange={handleSelectionModelChange}
                disableSelectionOnClick
                disableRowSelectionOnClick
                // autoPageSize
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              />


            </Stack>


          </div>
          :
          <div style={{ overflowY: "auto" }}>
            <Stack direction={"row"} spacing={2} padding={2}>

              <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "30%" }}>
                <InputLabel >School Board</InputLabel>
                <Select
                  disabled={disableOther}
                  labelId={`Product`}
                  label="School Board"
                  value={form?.board || ""}
                  onChange={(e) => boardHandler(e.target.value)}
                >
                  {board?.map((option, optionIndex) => (
                    <MenuItem key={option.boardId} value={option.boardId}>
                      {option.boardName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button variant="text" onClick={() => clearData()} >
                Clear
              </Button>
            </Stack>
            <hr style={{ width: '100%' }} />
            <Stack direction={"row"} spacing={2} padding={2}>
              <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "30%" }}>
                <InputLabel id={`select-label`}>Filter By Medium</InputLabel>
                <Select
                  disabled={disableOther}
                  labelId={`Product`}
                  label="School Medium"
                  value={productForm?.medium || ""}
                  onChange={(e) => mediumHandler(e.target.value)}
                >
                  {medium?.map((option, optionIndex) => (
                    <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
                      {option.mediumName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "30%" }}>

                <InputLabel id="select-label">Filter By Class</InputLabel>
                <Select
                  disabled={disableOther}
                  labelId="select-label"
                  id="select"
                  value={productForm?.classId || ""}
                  onChange={(e) => classHandler(e.target.value)}
                  label="Select Option"
                >
                  {className?.map((option, optionIndex) => (
                    <MenuItem key={option.classId} value={option.classId}>
                      {option.className}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: isMobile ? "100%" : "50%" }}>
                <InputLabel id="select-label">Filter By Series</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={productForm?.series || ""}
                  onChange={(e) => seriesHandler(e.target.value)}
                  label="Select Option"
                >
                  {getSeriesCategory?.map((option, optionIndex) => (
                    <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                      {option.seriesName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <hr style={{ width: '100%' }} />
            <Stack direction={"row"} spacing={2} padding={2}>
              <Autocomplete
                options={specimen}
                // getOptionLabel={}
                // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
                getOptionLabel={(option) => `${option?.nameSku}`}
                getOptionValue={(option) => option?.skuId || ''}
                style={{ width: "100%" }}
                value={selectSpecimen}
                // disabled={loading 
                // || !addNewMode || props.isManager
                // }
                noOptionsText={loading ? "Loading..." : "No option"}
                onChange={(event, value) => {
                  setSelectedSpecimen(value)
                  //  updateDataGrid(value);

                  // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                  // {
                  //     setDisableTransfer(false)
                  // }
                  // else
                  // setDisableTransfer(true)



                }}
                onInputChange={(e, value) => {
                  // Check if the user's input matches any option
                  // const matchingOption = employee.find((option) => option.name === value.name);

                  // if (!matchingOption ) {
                  //   // If there's no matching option, set the otherValue to null
                  // //   setDeliveryCity(null);
                  // // setAssignedEmp();
                  // return;
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // disabled={
                    //   loading 
                    // || !addNewMode || props.isManager || props.isDeleted
                    // }
                    label={"Choose SKU"}
                    required
                    variant="outlined"
                  />
                )} />
              <TextField
                label="Quantity"
                value={selectSpecimen?.qty || ""}
                type="text"
                fullWidth
                disabled={!selectSpecimen}
                onChange={(e) =>
                  setSelectedSpecimen((prevForm) => ({ ...prevForm, "qty": e.target.value, "totalPrice": e.target.value * selectSpecimen?.unitPrice }))
                }
              />
              <TextField label="Unit  Price" disabled value={selectSpecimen?.unitPrice || ""} type="text" fullWidth />

              <TextField label="Total Price" disabled value={selectSpecimen?.totalPrice || ""} type="text" fullWidth />

              <Button variant="text" onClick={() => additem1()} >
                Add
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={2} padding={1}>


              <DataGrid
                className="payrollGrid"
                //   checkboxSelection={true}
                style={{ height: !dataGridProduct.length ? '200px' : 'auto' }}
                density="compact"
                //   checkboxSelection={true}
                rows={dataGridProduct || []}
                columns={getColumns()}
                rowHeight={60}
                // onRowSelectionModelChange={handleSelectionModelChange}
                disableSelectionOnClick
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}//  autoPageSize
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              />


            </Stack>

          </div>}
        <div style={{ width: "55%" }}>
          <ModalHeaderContainer>
            Total Amount: {tabValue === 1 && calculateTotal(dataGridProduct) || 0}
            {/* <TextField label="Total Amount" value={tabValue===1&&calculateTotal(dataGridProduct)||""} type="text" disabled/> */}
            {status ?
              <Button variant="contained" onClick={() => onsubmit(form)} disabled={!Boolean(dataGridProduct.length)} >
                Place Order
              </Button> :
              <Button variant="contained" onClick={() => onEdit(form)} disabled={!Boolean(dataGridProduct.length)} >
                Update Order
              </Button>
            }</ModalHeaderContainer>
        </div>
      </div>
    )
  }


  const onEdit = async () => {
    try {
      setLoading(true)
      const filteredArray = dataGridProduct?.filter(element => Object.keys(element).every(key => key !== "orderLineItemId"));
      const finalData = {
        ...form, deletedRows: deletedIds, newRows: filteredArray, orderId: dataProp.orderId, totalAmount: calculateTotal(dataGridProduct)
      }
      const results = await window.Platform.database.updateOrderDetails(finalData);
      setLoading(false)
      onClose()
      fetchData()

    }
    catch (err) {
      setLoading(false)

      console.log(err)
    }
  }

  // const additem=()=>{
  //   if(bookType===2){
  //   console.log("SELECTEDPRODUCT",selectedProduct)
  //   const length=dataGrid.length+1
  //   let pushedItem={"qty":form.qty,"price":selectedProduct?.unitPrice,"total":form.qty*selectedProduct?.unitPrice,"itemId":selectedProduct?.skuId,id:length,"itemName":selectedProduct?.nameSku, series: selectedProduct?.seriesCategory, productGroup: productGroup.id}
  //   if (!pushedItem.series) {
  //     window.NotificationUtils.showError("Please select series.");
  //     return;
  //   }

  const additem = () => {
    if (bookType === 2) {
      const length = dataGridProduct.length + 1
      let pushedItem = { "qty": productForm?.qty, "price": selectedProduct?.unitPrice, "total": productForm?.qty * selectedProduct?.unitPrice, "itemId": selectedProduct?.skuId, id: length, "itemName": selectedProduct?.nameSku, series: selectedProduct?.seriesCategory, productGroup: productGroup?.id }
      setSelectedProduct(null)
      setDataGridProduct([...dataGridProduct, pushedItem])
    }
    else if (bookType === 1) {
      setDataGridProduct([...dataGridProduct, ...seriesAdd])
      setSelectedSeries(null)
    }
    setProductForm({
      qty: ''
    })
    setProductType(null);
    setProduct(null);
    clearData();
  }

  const additem1 = () => {
    const length = dataGridProduct.length + 1
    let pushedItem = { "qty": selectSpecimen.qty, "price": selectSpecimen.unitPrice, "total": selectSpecimen.totalPrice, "itemId": selectSpecimen.skuId, id: length, "itemName": selectSpecimen.nameSku, series: selectedProduct?.seriesCategory, productGroup: productGroup.id };
    setSelectedSpecimen(null)
    setDataGridProduct([...dataGridProduct, pushedItem])
    clearData();
  }



  const boardHandler = (data) => {
    if (orderType === 1) {
      setDisableSeries(true)
      changeHandler("board", data)
      setSelectDataFilter((prevForm) => ({ ...prevForm, board: data }))
    }
    else {
      changeHandler("board", data)
      setSelectDataFilter1((prevForm) => ({ ...prevForm, board: data }))
    }
  }
  const seriesHandler = (data) => {

    if (orderType === 1) {
      setDisableSeries(true)
      changeHandlerProduct("series", data)
      setSelectDataFilter((prevForm) => ({ ...prevForm, series: data }))
    }
    else {

      changeHandlerProduct("series", data)
      setSelectDataFilter1((prevForm) => ({ ...prevForm, series: data }))
    }

  }
  const mediumHandler = async (data) => {
    if (orderType === 1) {

      setDisableSeries(true)
      changeHandlerProduct("medium", data)
      setSelectDataFilter((prevForm) => ({ ...prevForm, medium: data }))
    } else {
      changeHandlerProduct("medium", data)
      setSelectDataFilter1((prevForm) => ({ ...prevForm, medium: data }))
    }
  }
  const classHandler = (data) => {
    if (orderType === 1) {

      setDisableSeries(true)
      changeHandlerProduct("classId", data)
      setSelectDataFilter((prevForm) => ({ ...prevForm, class: data }))
    }
    else {
      changeHandlerProduct("classId", data)
      setSelectDataFilter1((prevForm) => ({ ...prevForm, class: data }))
    }
  }

  const getBoard = async (data) => {
    let results = await window.Platform.database.getBoard();
    setBoard(results.data)


  }
  const getMedium = async (data) => {
    let results = await window.Platform.database.getMedium();
    const finalData = results?.data?.filter(medium => medium.mediumTableId != "buCiAvMirI")
    setMedium(finalData)


  }



  const fetchProduct = async (data) => {
    const results = await window.Platform.database.fetchProduct(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setProduct(jsonArrayWithId)
    setProduct1(jsonArrayWithId)

  }

  const getClass = async (data) => {
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setClassName(jsonArrayWithId)

  }

  const fetchProductById = async (data) => {
    const results = await window.Platform.database.fetchProductById({ id: data });
    const length = dataGridProduct.length
    const mappedArray = results?.data?.map((selectSpecimen, index) => ({
      qty: productForm?.qty,
      price: selectSpecimen?.unitPrice,
      total: productForm?.qty * selectSpecimen?.unitPrice,
      itemId: selectSpecimen?.skuId,
      id: index + length + 1, // assuming you want a unique identifier based on the array index
      itemName: selectSpecimen?.nameSku,
      productGroup: productGroup.id
    }));
    setSeriesAdd([...mappedArray])
  }

  const fetchEmployees = async (data) => {
    setLoading(true)
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
    setAssignedEmp(null)
    if (!status) {
      const filteredEmp = results?.data?.filter((data) => data.id === dataProp.ownerId)
      setAssignedEmp(filteredEmp[0])
    }
    setLoading(false)

  }
  const calculateTotal = (selectedRows) => {
    if (!Array.isArray(selectedRows)) {
      return 0; // Default value if selectedRows is not an array
    }
    const totalWeight = selectedRows.reduce(
      (sum, row) => Number(sum) + Number(row.total ? row.total : row.Total),
      0
    );
    return Math.ceil(totalWeight);
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.ownerId?.trim()) {
      errors.ownerId = "Assign to is required";
    }

    if (!data.partyId?.trim()) {
      errors.partyId = "Party is required";
    }

    if(!data.email){
      errors.email = 'Email is required';
    }
    if (data.email) {
      if (!/\S+@\S+\.\S+/.test(data.email)) {
        errors.email = 'Email is invalid';
      }
    }
    if (!data.mobileNo?.trim()) {
      errors.mobileNo = "mobile Number is required";
    }
if (!data.transport?.trim()) {
  errors.transport = "transport is required";
}
if (!data.address?.trim()) {
  errors.address = "send address is required";
}

    return errors;
  };

  const validateFormProduct = (data) => {
    const errors = {};

    if (!dataGridProduct.length) {
      if (!data.qty?.trim()) {
        errors.qty = "Quantity is required";
      }

      if (!data.price?.toString()?.trim()) {
        errors.price = "Price is required";
      }

      if (!data.total?.trim()) {
        errors.total = "Total is required";
      }

      if (!data.productGroup?.trim()) {
        errors.productGroup = "Product Group is required";
      }
    }
    return errors;
  };

  const onsubmit = async (data) => {
    try {
      const errors = validateForm(data);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true)
        const totalAmount = calculateTotal(dataGridProduct)
        let ordersList = [];
        if (!dataGridProduct.length) {
          ordersList = [...dataGridProduct];
        } else {
          ordersList = [...dataGridProduct];
        }
        let finalData = { ...form, orders: ordersList, totalAmount: totalAmount }

        //  const results = await window.Platform.database.createOrder({...form,orders:dataGrid,totalAmount:totalAmount});

        window.NotificationUtils.showSuccess("Order Placed Successfully!")
        onClose1(finalData)
        setLoading(false)
      } else {
        setLoading(false);
        window.NotificationUtils.showError("Please fill the all required fields.")
      }



    }
    catch (error) {
      console.log(error)
      setLoading(false)

    }
  }
  const getColumns = () => {
    let result = [
      // {
      //   field: "skuId",
      //   headerName: "SKU-Id",
      //   sortable: false,
      //   width: 200,

      //   editable: false,
      //   renderCell: (params) => {

      //     let name = params.row?.itemId||"N/A"

      //     return (

      //       <Tooltip title={name || "N/A"}>

      //         <Typography variant="inherit">{name}</Typography>

      //       </Tooltip>

      //     )

      //   },
      // },
      {
        field: "nameSku",
        headerName: "Name",
        width: 250,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
          let name = params.row.itemName ? params.row.itemName : params.row.nameSku || "N/A"
          if (name === "N/A") {
            name = params.row.product_name || "N/A"
          }
          return (

            <Tooltip title={name || "N/A"}>

              <Typography variant="inherit">{name}</Typography>

            </Tooltip>

          )

        },
      }
      ,
      {
        field: "qty",
        headerName: "Quantity",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {

          let name = params.row.qty ? params.row.qty : params.row.QTY || 0

          return (

            <Tooltip title={name || 0}>

              <Typography variant="inherit">{name}</Typography>

            </Tooltip>

          )

        },
      },
      {
        field: "Price Unit",
        headerName: "Unit Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {

          let name = params.row.price ? params.row.price : params.row.Price || 0

          return (

            <Tooltip title={name || 0}>

              <Typography variant="inherit">{name}</Typography>

            </Tooltip>

          )

        },
      },
      {
        field: "Price",
        headerName: "Total Price",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {

          let name = params.row.total ? params.row.total : params.row.Total || 0

          return (

            <Tooltip title={name || 0}>

              <Typography variant="inherit">{name}</Typography>

            </Tooltip>

          )

        },
      },
      {
        field: "productGroup",
        headerName: "Product Group",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let name = params.row.productGroup || "N/A"
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{(name === productGroupMittsure.id) ? productGroupMittsure.name : productGroupMittplus.name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {

          let name = params.row.total || "N/A"

          return (

            <IconButton onClick={() => handleDeleteEmploye(params.row.id)}>
              <DeleteIcon />
            </IconButton>

          )

        },
      },




    ];


    return result
  }

  const handleDeleteEmploye = async (id) => {
    if (!status) {
      let res1 = dataGridProduct?.filter((el) => {
        return el.id === id;
      });
      if (res1[0]?.orderLineItemId) {
        setDeletedIds([...deletedIds, res1[0].orderLineItemId])
      }
    }
    let res = dataGridProduct?.filter((el) => {
      return el.id != id;
    });

    setDataGridProduct(res);
  };
  const clearData = () => {
    if (orderType === 1) {

      setSelectedSeries(null)
      setDisableOther(false)
      setDisableSeries(false)
      setSelectedProduct(null)
      setBookType(null)
      setSelectDataFilter({ board: null, medium: null, class: null, series: null })
      setBookType(null)
      // setForm((prevForm) => ({ ...prevForm, board:null, medium: null, classId:null,series:null,qty:null }));
      setProductForm({
        itemId: "",
        productGroup: "",
        qty: "",
        price: "",
        total: "",
        series: "",
        classId: ''
      })
    }
    else {
      setSelectDataFilter1({ board: null, medium: null, class: null, series: null })
      setForm((prevForm) => ({ ...prevForm, board: null, medium: null, classId: null, series: null }));
      setProductForm({
        itemId: "",
        productGroup: "",
        qty: "",
        price: "",
        total: "",
        series: "",
        classId: ''
      })

    }
  }
  return (
    <Dialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true} >
      {loading && <OpaqueLoading />}
      <ModalHeaderContainer style={{ marginBottom: 2, paddingBottom: 3 }}>
        <Typography variant={isMobile ? "h6" : "h5"}>
          Place Order
          <Tooltip title="Order Id">

            {id}
          </Tooltip>

        </Typography>
        <IconButton onClick={onClose} >
          <CloseOutlined />
        </IconButton>
      </ModalHeaderContainer>
      <TableContainer>

        <TabsContainer>
          <StyledTabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Order Details" />
            {form?.orderType && <Tab label="Product Details" />}

          </StyledTabs>
          {/* {tabValue==1? renderContent() :renderContent1()} */}

          {/* {
              tabValue === 1 ? 
              (renderPartnerForm
            ? <>
                <Stack direction={"row"} spacing={2} padding={2}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>Product Group</InputLabel>
                    <Select
                      labelId={`product-group`}
                      label="Product Group"
                      value={productGroup || ""}
                      onChange={(e) => productGroupHandler(e.target.value)}
                    >
                      {mittplusProductGroups?.map((option, optionIndex) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {renderContentPartner()}
              </>
              : 
              <div>
                <Stack direction={"row"} spacing={2} padding={2}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>Product Group</InputLabel>
                    <Select
                      labelId={`product-group`}
                      label="Product Group"
                      value={productGroup || ""}
                      onChange={(e) => productGroupHandler(e.target.value)}
                    >
                      {mittplusProductGroups?.map((option, optionIndex) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {renderContentSubscription()}
              </div>
            )
            : renderContent1()
            } */}

          {
            tabValue === 1 ?
              <>
                <Stack direction={"row"} spacing={2} padding={2}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>Product Group</InputLabel>
                    <Select
                      labelId={`product-group`}
                      label="Product Group"
                      value={productGroup || ""}
                      onChange={(e) => productGroupHandler(e.target.value)}
                      disabled={orderType === 2}
                    >
                      {productGroups?.map((option, optionIndex) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {
                  renderMittplusForm ?
                    <>
                      <Stack direction={"row"} spacing={2} padding={2}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                          <InputLabel>Product Type</InputLabel>
                          <Select
                            labelId={`product-type`}
                            label="Product Type"
                            value={productType || ""}
                            onChange={(e) => productTypeHandler(e.target.value)}
                          >
                            {mittplusProductGroups?.map((option, optionIndex) => (
                              <MenuItem key={option.id} value={option}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                      {renderContentMittplus()}
                    </>
                    :
                    renderContent()
                }
              </>
              :
              <>
                {renderContent1()}
              </>
          }

        </TabsContainer>

      </TableContainer>

    </Dialog>
  );
};

export default AddOrderModal;