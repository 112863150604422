import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    IconButton,
    TextField,
    Typography,
    Tabs,
    Tab,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;

const TableContainer = styled.div`
  width: ${isMobile ? "100%" : "98%"};
  border-radius: 8px;
  margin-left: ${isMobile ? "0px" : "10px"};
  margin-bottom: ${isMobile ? "0px" : "10px"};
`;

const TabsContainer = styled.div`
  ${isMobile &&
    `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  margin-top: 10px;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AddNewIncentiveModal = ({
    props,
    onClose,
    id,
    fetchData,
}) => {
    const [tabValue, setTabValue] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    const [incentivePercentage, setIncentivePercentage] = useState('');
    const [role, setRole] = useState('');
    const [series, setSeries] = useState('');
    const [roleData, setRoleData] = useState([])
    const [seriesData, setSeriesData] = useState([])
    const [loading, setLoading] = useState(false)
    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleSeriesChange = (e) => {
        setSeries(e.target.value);
    };


    const submitIncentive = async () => {
        setLoading(true)
        try {
            let data = {
                role: role,
                series:series,
                minAmount: minAmount,
                maxAmount: maxAmount,
                incentivePercent: incentivePercentage,
            }
            let response = await window.Platform.database.addIncentiveNew(data);

            if (response.data) {
                window.NotificationUtils.showSuccess("Incentive added successfully!");
                setLoading(false);
                onClose();
                fetchData();
            }

        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    const updateIncentive = async () => {
        setLoading(true)
        try {
            let data = {
                role: role,
                series:series,
                minAmount: minAmount,
                maxAmount: maxAmount,
                incentivePercent: incentivePercentage,
                id:props.id
            }
            let response = await window.Platform.database.editIncentive(data);

            if (response.data) {
                window.NotificationUtils.showSuccess("Incentive Updated successfully!");
                setLoading(false);
                onClose();
                fetchData();
            }

        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };


    const fetchRole = async () => {
        setLoading(true)
        let response = await window.Platform.database.fetchRoles();
        if (response.status == false && response.data) {
            setRoleData(response.data)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    const fetchSeries = async () => {
        setLoading(true)
        let results = await window.Platform.database.getSeriesCategory();
        if (results.status == false && results.data) {
            setSeriesData(results.data)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
      }
    useEffect(() => {
        if (props) {
            setMinAmount(props.minAmount);
            setMaxAmount(props.maxAmount);
            setIncentivePercentage(props.incentivePercent);
            setRole(props.role);
            setSeries(props.seriesId)
        }
        fetchRole()
        fetchSeries()
    }, [])

    const isSubmitDisabled = !role || !series || !incentivePercentage || (!minAmount && !maxAmount);

    const renderContent = () => {
        return (
            <div className="incentive-management">
                <h2 className="incentive-managementtitle">Incentive Management</h2>

                <Stack direction="row" spacing={2} padding={0}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel style={{ background: 'white', padding: '0 4px' }}>Select Role</InputLabel>
                        <Select
                            style={{ fontSize: "1rem" }}
                            labelId="Select Role"
                            value={role}
                            onChange={handleRoleChange}
                        >
                            {roleData.map((option, index) => (
                                <MenuItem key={index} value={option.roleName}>
                                    {option.roleName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel style={{ background: 'white', padding: '0 4px' }}>Select Series</InputLabel>
                        <Select
                            style={{ fontSize: "1rem" }}
                            labelId="Select Series"
                            value={series}
                            onChange={handleSeriesChange}
                        >
                            {seriesData.map((option, index) => (
                                <MenuItem key={index} value={option.seriesTableId}>
                                    {option.seriesName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <div className="incentive-managementform-group">
                    <label htmlFor="amount-range" className="incentive-managementform-label">Amount Range:</label>

                    <Stack direction={"row"} spacing={2} padding={0}>
                        <TextField
                            label="minimum Amount"
                            type="text"
                            fullWidth
                            value={minAmount}
                            onChange={(e) => setMinAmount(e.target.value)}
                        />
                        <span className="" style={{ marginTop: "14px", fontSize: "1rem" }}>to</span>
                        <TextField
                            label="maximum Amount"
                            type="text"
                            fullWidth
                            value={maxAmount}
                            onChange={(e) => setMaxAmount(e.target.value)}
                        />
                    </Stack>
                </div>
                
                <label htmlFor="incentivePercentage" className="incentive-managementform-label">Incentive Percentage:</label>
                <Stack direction={"row"} spacing={2} padding={0}>
                    <FormControl style={{ width: "100%" }}>
                        <TextField
                            label="Enter Incentive %"
                            value={incentivePercentage}
                            type="text"
                            fullWidth
                            onChange={(e) => setIncentivePercentage(e.target.value)}
                        />
                    </FormControl>
                </Stack>
                <div style={{ marginTop: "30px" }}>
                {!props ?  <Button
                        className="incentive-managementsubmit-button"
                        variant="contained"
                        color="primary"
                        onClick={submitIncentive}
                        disabled={isSubmitDisabled}
                    >
                        Submit
                    </Button> :
                    <Button
                        className="incentive-managementsubmit-button"
                        variant="contained"
                        color="primary"
                        onClick={updateIncentive}
                        disabled={isSubmitDisabled}
                    >
                        Update
                    </Button>}
                </div>
            </div>
        );
    };

    return (
        <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}>
            {loading && <OpaqueLoading />}
            <ModalHeaderContainer style={{ marginBottom: 2, paddingBottom: 3 }}>
                <Typography variant={isMobile ? "h6" : "h5"}>
                    Add Incentive
                    <Tooltip title="Order Id">{id}</Tooltip>
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </ModalHeaderContainer>
            <TableContainer>
                <TabsContainer>
                    <StyledTabs
                        variant="fullWidth"
                        value={tabValue}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Incentive Details " />
                    </StyledTabs>
                    {tabValue == 0 && renderContent()}
                </TabsContainer>
            </TableContainer>
        </Dialog>
    );
};

export default AddNewIncentiveModal;
