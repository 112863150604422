import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const WareHouseModal = ({ onClose, fetchData, propData }) => {
  const [name, setName] = useState("");
  const [wh_code, setWh_code] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (propData) {
      setName(propData.name || "");
      setWh_code(propData.wh_code || "");
    }
  }, [propData]);

  const submitHandler = async () => {
    if (!name.trim() || !wh_code.trim()) {
      window.NotificationUtils.showError("Please fill all fields");
      return;
    }

    setLoading(true);
    try {
      if (propData) {
        await window.Platform.database.updateWarehousekInPicklist({
          name,
          id: propData.id,
          wh_code,
        });
        window.NotificationUtils.showSuccess("Warehouse updated successfully");
      } else {
        await window.Platform.database.addWarehouseInPicklist({ name, wh_code });
        window.NotificationUtils.showSuccess("Warehouse saved successfully");
      }
      setName("");
      setWh_code("");
      fetchData();
      onClose();
    } catch (err) {
      window.NotificationUtils.showError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog disableEnforceFocus maxWidth="sm" fullWidth open>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{propData ? "Edit" : "Add"} Warehouse</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {loading && <OpaqueLoading />}

        <Stack gap={2} padding={2}>
          <FormControl fullWidth>
            <TextField
              label="Warehouse Name"
              value={name}
              type="text"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Warehouse Code"
              value={wh_code}
              type="text"
              onChange={(e) => setWh_code(e.target.value)}
              fullWidth
            />
          </FormControl>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={submitHandler}
              disabled={!name || !wh_code}
            >
              {propData ? "Update" : "Create"}
            </Button>
          </div>
        </Stack>
      </StyledDialogContent>
    </Dialog>
  );
};

export default WareHouseModal;
