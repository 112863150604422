import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const RemarkModal = ({ onClose,fetchData,propData }) => {
  const [form, setForm] = useState({ name: "" });
  const [loading, setLoading] = useState(false);
    const [name, setName] = useState("")
    const [remark, setRemarks] = useState("")
  useEffect(() => {
    getSeriesCategoryData();
  }, []);
  useEffect(() => {
    if (propData) {
      setName(propData.name || "");
    }
  }, [propData]);
console.log(propData,"propDatapropData");

  const getSeriesCategoryData = async () => {
    let results = await window.Platform.database.getSeriesCategory();
    setRemarks(results.data);
  };

  const submitHandler = async () => {
      if (!name.trim()) {
          window.NotificationUtils.showError("Please fill in Remark fields");
          return;
        }
        
        setLoading(true);
    // Ensure that the values are numbers
    try {
        if (propData) {
          await window.Platform.database.updateRemarkInPicklist({
            name: name,
            id: propData.id,
          });
          window.NotificationUtils.showSuccess("Remark updated successfully");
        } else {
          await window.Platform.database.addRemarkInPicklist({ name });
          window.NotificationUtils.showSuccess("Remark saved successfully");
        }
        setName("");
        fetchData();
        onClose();
      } catch (err) {
        window.NotificationUtils.showError(err?.message);
      } finally {
        setLoading(false);
      }
  };

  return (
    <Dialog disableEnforceFocus maxWidth="sm" fullWidth open>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Remark</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {loading && <OpaqueLoading />}

        <Stack gap={2} padding={2}>
          <FormControl fullWidth>
            
                      <TextField
                        label="Remark Name"
                        value={name || ''}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />           
          </FormControl>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={submitHandler}
                          disabled={!name}
                        >
                          {propData ? 'Update' : 'Create'}
                        </Button>
                      </div>
        </Stack>
      </StyledDialogContent>

    </Dialog>
  );
};

export default RemarkModal;
