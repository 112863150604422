import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  CardContent,
  Card
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const ProductModal = ({ props,onClose,remarks,data,id,distributorId,fetchData,selectedTab, status}) => {
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  const [loading,setLoading]=useState(false)
// const [product,setProduct]=useState()

 const [form, setForm] = useState({
  name: props?.nameSku || '',
  id: props?.id || '',
  unitPrice: props?.unitPrice || '',
  skuCode: props?.sku_code || '',
  board: props?.boardId || '',
  medium: props?.mediumTableId || '',
  class: props?.classId || '',
  seriesCategoryId: props?.seriesTableId || '',
  specimen:props?.is_specimen ||"",
  subject: props?.subjectId || '',
  type:props?.type || ""
 });
 const [form1,setForm1]=useState({})

 const [employee,setEmployee]=useState([])

const [product,setProduct]=useState([])
const [product1,setProduct1]=useState([])

const [selectProduct,setSelectProduct]=useState()
const [selectProduct1,setSelectProduct1]=useState()
const [board,setBoard]=useState()
const [medium,setMedium]=useState()
const [className,setClassName]=useState()
const [selectDataFilter,setSelectDataFilter]=useState({
    board:'',class:'',medium:'',sku:'',price:''
})

const [selectedBoard,setSelectedBoard]=useState()
const [selectedMedium,setSelectedMedium]=useState()
const [selectedClass,setSelectedClass]=useState()
const [dataGrid,setDataGrid]=useState([])
const [subject,setSubject]=useState()
const [getSubjects,setGetSubject]=useState()
const [getSeriesCategory,setgetSeriesCategory]=useState()
const [seriesCategory,setSeriesCategory]=useState()
  const [errors, setErrors] = useState({});












  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
   

  
 
useEffect(()=>{
    getMedium()
    getBoard()
    getClass()
    getSubject()
    getSeriesCategoryData()
},[])

const validateForm = (data) => {
  const errors = {};

  if (!data.name?.trim()) {
    errors.skuName = "SKU name is required";
  }

  if (!data.skuCode?.trim()) {
    errors.skuCode = "SKU code is required";
  }

  if (!data.unitPrice?.trim()) {
    errors.unitPrice = "Unit price is required";
  }

  if (!data.specimen) {
    errors.specimen = "specimen is required";
  }

  if (!data.type) {
    errors.type = "Book type is required";
  }

  console.log("errors", errors)
    
  return errors;
};

 
const changeHandler = (fieldName, value) => {

    let val = value;
    if (fieldName === "unitPrice") {
      val = val.replace(/\D/g, "");
    }
    setForm((prevForm) => ({ ...prevForm, [fieldName]: val }));
  };
console.log(form,"====>>>>form")

const boardHandler=(data)=>{
    changeHandler("board", data);
    const boardData = board.find((item) => item.boardId === data);
    changeHandler("boardName",boardData.boardName)
    setSelectedBoard(data)
}

const mediumHandler=(data)=>{
    changeHandler("medium", data);
    // changeHandler("mediumName",data.mediumName)
    const mediumData = medium.find((item) => item.mediumTableId === data);
    changeHandler("mediumName", mediumData.mediumName)
    setSelectedMedium(data)

}
const classHandler=(data)=>{
    changeHandler("class", data);
    // changeHandler("className",data.className)
    const classData = className.find((item) => item.classId === data);
    changeHandler("className", classData.className)
    setSelectedClass(data)

}

const subjectName=(data)=>{
  changeHandler("subject",data)
  // changeHandler("subjectName",data.subjectName)
  const subjectData = getSubjects.find((item) => item.subjectId === data);
  changeHandler("subjectName", subjectData.subjectName)
  setSubject(data)

}
console.log(form,"<<<>>>form");

const seriesHandler=(data)=>{
  changeHandler("seriesCategoryId",data)
  changeHandler("seriesName",data.seriesName)
  const seriesData = getSeriesCategory.find((item) => item.seriesTableId === data);
  changeHandler("seriesName", seriesData.seriesName)
  setSeriesCategory(data)

}

const renderContent1=()=>{
  return (
    <div style={{ overflowY:"auto" }}>

  <Stack direction={"row"} spacing={2} padding={2}>
  <FormControl variant="outlined" style={{ width: isMobile?"100%":"50%" }}>
          <InputLabel > Board</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            value={form?.board}
            onChange={(e) => boardHandler(e.target.value)}
            required
            error={errors.board ? true : false}
          >
            {board?.map((option, optionIndex) => (
    <MenuItem key={option.boardId} value={option.boardId}>
      {option.boardName}
    </MenuItem>
  ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ width: isMobile?"100%":"50%" }}>
          <InputLabel id={`select-label`}> Medium</InputLabel>
          <Select
            labelId={`Product`}
            label="School Medium"
            // value={selectedMedium}
            value={form?.medium}
            onChange={(e) => mediumHandler(e.target.value)}
            required
            error={errors.medium ? true : false}
          >
            {medium?.map((option, optionIndex) => (
    <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
      {option.mediumName}
    </MenuItem>
  ))}
          </Select>
    </FormControl>
   
          </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <FormControl style={{ width: isMobile ? "100%" : "50%" }}>
          <InputLabel id="select-label">Class</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            // value={selectedClass}
            value={form?.class}
            onChange={(e) => classHandler(e.target.value)}
            label="Select Option"
            required
            error={errors.class ? true : false}
          >
            {className?.map((option, optionIndex) => (
              <MenuItem key={option.classId} value={option.classId}>
                {option.className}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: isMobile ? "100%" : "50%" }}>
          <InputLabel id="select-label">Subject</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            // value={subject}
            value={form?.subject}
            onChange={(e) => subjectName(e.target.value)}
            label="Select Option"
            required
            error={errors.subject ? true : false}
          >
            {getSubjects?.map((option, optionIndex) => (
              <MenuItem key={option.subjectId} value={option.subjectId}>
                {option.subjectName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction={"row"} spacing={2} padding={2}>
        <FormControl style={{ width: isMobile ? "100%" : "50%" }}>
          <InputLabel id="select-label">Series</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            // value={seriesCategory}
            value={form?.seriesCategoryId}
            onChange={(e) => seriesHandler(e.target.value)}
            label="Select Option"
            required
            error={errors.series ? true : false}
          >
            {getSeriesCategory?.map((option, optionIndex) => (
              <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                {option.seriesName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ width: isMobile ? "100%" : "50%" }}>
          <TextField label="SKU Name" value={form?.name} type="text" fullWidth onChange={(e) => changeHandler("name", e.target.value)}
            required
            error={errors.skuName ? true : false}
          />
        </FormControl>
      </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="SKU Code" value={ form?.skuCode } type="text" fullWidth onChange={ (e)=> changeHandler("skuCode", e.target.value) } 
            required
            error={errors.skuCode ? true : false}
          />
          <TextField label="Unit Price" value={form?.unitPrice} type="text"fullWidth onChange={(e)=>changeHandler("unitPrice",e.target.value)}
            required
            error={errors.unitPrice ? true : false}
          />
</Stack> 
<Stack direction={"row"} spacing={2} padding={2}>
<FormControl variant="outlined" style={{ width: isMobile ? "100%" : "50%" }} required error={errors.specimen}>
      <InputLabel id="specimen">Specimen</InputLabel>
      <Select
        labelId="specimen"
        label="Specimen"
        value={form?.specimen} 
        onChange={(e) => changeHandler("specimen", e.target.value)} 
        required
        error={errors.specimen ? true : false}
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>

    <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "50%" }} required error={errors.type}>
      <InputLabel id="type">Book Type</InputLabel>
      <Select
        labelId="type"
        label="type"
        value={form?.type} 
        onChange={(e) => changeHandler("type", e.target.value)} 
        required
        error={errors.type ? true : false}
      >
        <MenuItem value="book">book</MenuItem>
        <MenuItem value="set">set</MenuItem>
      </Select>
    </FormControl>

</Stack>


      {status && <Stack direction={"row"} spacing={2} padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained"  onClick={()=>handleAdd(form)}>
            Add
        </Button>
      </Stack>}

 {status && <Stack direction={"row"} spacing={2} padding={2}>

       
<DataGrid
className="payrollGrid"
//   checkboxSelection={true}
style={{ height: !dataGrid.length ? '200px' : 'auto' }}
density="compact"
// checkboxSelection={true}
rows={dataGrid||[]}
columns={getColumns()}
rowHeight={60}
// onRowSelectionModelChange={handleSelectionModelChange}
disableSelectionOnClick
disableRowSelectionOnClick

initialState={{
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
}}
pageSizeOptions={[10]}
// autoPageSize
// components={{
//   Toolbar: CustomToolbar,
// }}
/>
    

       
             
         
    </Stack> }
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

 <Button variant="contained" /*disabled={!dataGrid.length}*/ onClick={ status ? () => onsubmit(form) : () => onUpdate(form) } >
    { status ? 'Create' : 'Update' }
 </Button>
 </div>
      
</div>
)}

const getSubject=async()=>{
  let results = await window.Platform.database.getSubject();
  setGetSubject(results.data)
  if (props?.subjectId) {
    const item = results.data.filter((result) => result.subjectId === props.subjectId)
    setSubject(item);
  }

}

const getSeriesCategoryData=async()=>{
  let results = await window.Platform.database.getSeriesCategory();
  setgetSeriesCategory(results.data)
  if (props?.seriesTableId) {
    const item = results.data.filter((result) => result.seriesTableId === props.seriesTableId)
    setSeriesCategory(item);
  }

}



  const getBoard=async()=>{
    let results = await window.Platform.database.getBoard();
    setBoard(results.data)
    if (props?.boardId) {
      const item = results.data.filter((result) => result.boardId === props.boardId)
      setSelectedBoard(item);
    }
  }

  const getMedium=async()=>{
    let results = await window.Platform.database.getMedium();
    setMedium(results.data)
    if (props?.mediumTableId) {
      const item = results.data.filter((result) => result.mediumTableId === props.mediumTableId)
      setSelectedMedium(item);
    }
  }

  const handleDeleteEmploye = async (id) => {
    // const response = await window.Platform.database.deleteUsers(ID);

    let res = dataGrid.filter((el) => {
      return el.id != id;
    });

    setDataGrid(res);
  };

  const getColumns = () => {
    let result = [
     
      {
        field: "nameSku",
        headerName: "SKU Name",
        width: 170,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
          let name =params.row.name||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
          field: "Price",
          headerName: "Unit Price",
          width: 150,
          // height: 10,
          // headerAlign: 'center',
          sortable: false,
          // editable: true,
          renderCell: (params) => {
    
            let name =params.row.unitPrice||"N/A"
    
            return (
    
              <Tooltip title={name || "N/A"}>
    
                <Typography variant="inherit">{name}</Typography>
    
              </Tooltip>
    
            )
    
          },
        },
        {
            field: "board",
            headerName: "Board",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.boardName||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "Medium",
            headerName: "Medium",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.mediumName||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "Class",
            headerName: "Class",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.className||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "Subject",
            headerName: "Subject",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.subjectName||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "Series",
            headerName: "Series",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.seriesName||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "type",
            headerName: "Book Type",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.type||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "Actions",
            headerName: "Actions",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.total||"N/A"
      
              return (
      
               <IconButton onClick={()=>handleDeleteEmploye(params.row.id)}>
<DeleteIcon/>
               </IconButton>
      
              )
      
            },
          },
  
       
  
   
    ];
  
  
    return result
  }

 

   const getClass=async(data)=>{
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setClassName(jsonArrayWithId)

    if (props?.classId) {
      const item = jsonArrayWithId.filter((result) => result.classId === props.classId)
      setSelectedClass(item);
    }
   }
 
 
  const onsubmit=async(data)=>{
    try{
      setLoading(true)
        await window.Platform.database.addProduct({orders: dataGrid});

        window.NotificationUtils.showSuccess("Product added successfully!");
        setLoading(false);
        onClose();
        fetchData();
  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }

  const onUpdate = async (data) => {
    try {
      setLoading(true)
      const errors = validateForm(form);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        await window.Platform.database.updateProduct(data);

        window.NotificationUtils.showSuccess("Product updated successfully!");
        setLoading(false);
        onClose();
        fetchData();
      } else {
        setLoading(false);
        window.NotificationUtils.showWarning("Please fill all required fields to proceed");
      }
    }
    catch (error) {
      console.log(error)
      setLoading(false)

    }
  }

const handleAdd=(data)=>{

  const errors = validateForm(form);
  setErrors(errors);

    if(Object.keys(errors).length === 0) {
    // if(data.name && data.board && data.medium && data.classId){
    let pusheItem={...data,id:dataGrid.length+1}

  setDataGrid([...dataGrid,pusheItem])

  setSelectedBoard()
  setSelectedMedium()
  setSelectedClass()
  setSeriesCategory()
  setSubject()
  setForm({
    name: '',
    unitPrice: '',
    skuCode: '',
  })

    }
    else{
    window.NotificationUtils.showWarning("Please Fill All Data")

    } 


}

  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}  >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         { status ? 'Add Mittsure Product' : 'Edit Mittsure Product' }
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Product Details " />
            
           
            </StyledTabs>
            {tabValue==0&&renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default ProductModal;