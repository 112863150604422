import { Avatar, Button, CircularProgress, Divider, Fab, Tab, Tabs, Tooltip, Typography, IconButton, Stack } from '@mui/material';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import React, { useState, useEffect, useContext } from 'react'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddOnProductModal from "../../components/modals/AddOnProductModal";
import AddOnProductNewModal from '../../components/modals/AddOnProductNewModal';
import {

  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridDensitySelector,
  getGridStringOperators,
} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';

import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json"

import Drawer from "../../components/common/drawer/drawer";

import { TABS } from '../../constants';
import moment from 'moment';
import { AuthContext } from '../../components/contextAPI/ContextAPI';
import { Add, AddAPhoto, AddTask, SaveAlt, SaveAltOutlined, SaveAs, ManageSearch, Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';
import "../../App.css"

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
      `}
`;

const HeaderContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:7px;
`
const TableContainer = styled.div`
//   height: calc(
//     100vh - ${isMobile ? "56px - 20px - 82.23px" : "128px - 29px - 50.77px"}
//   );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DetailsPreviewContainer = styled.div`
    height:100%;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    ${isMobile && `
    height: calc(100vh - 56px - 20px);
  `}

`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
//   border: solid 1px lightGrey;
  display: ${isMobile ? 'flex' : 'grid'};
  border-radius: 4px;

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
height: calc(100% - 49px);
display:flex;
justify-content:center;
align-items:center;
`
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const BulkUploadButton = styled(Button)`
  margin-right:10px !important;
`
const FabStyle = styled(Fab)`
  && {
    z-index: 1};

  }

`;

const AddOnProducts = (props) => {
  const location = useLocation();
  const selectedTabProp1 = location.state?.selectedTabProp;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 11,
  });
  const [paginationModel1, setPaginationModel1] = useState({
    page: currentPage1 - 1,
    pageSize: 11,
  });
  const [rowCount, setRowCount] = useState()
  const [rowCount1, setRowCount1] = useState()
  const navigation = useNavigate()
  const [userModal, setUserModal] = useState(null)
  const [visibleEmployees, setVisibleEmployees] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedTab, setSelectedTab] = useState(selectedTabProp1 || TABS.REGISTERED);
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useContext(AuthContext)
  const [addOnProductModalOpen, setAddOnProductModalOpen] = useState(false);
  const [addOnProductNewModalopen, setAddOnProductNewModalopen] = useState(false);
  const [editAddOnProduct, setEditAddOnProduct] = useState(false);
  const [editData, setEditData] = useState();
  const [editData1, setEditData1] = useState();
  const [editAddOnNewProduct, setEditAddOnNewProduct] = useState(false);
  const [filter, setFilter] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)
  const [searchTerm1, setSearchTerm1] = useState(null)
  const [otpModalData, setOtpModalData] = useState()
  const [otpModalDataDistributor, setOtpModalDataDistributor] = useState()

  useEffect(() => {
    fetchEmployees();
  }, [selectedTab, paginationModel.page, paginationModel1.page])




  let contextState = { ...auth }
  const schoolColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
    let result = [
      // {
      //   field: "addOnProductName",
      //   headerName: "Name",
      //   minWidth: 250,
      //   valueGetter: (params) => {
      //     let name = params.row?.name
      //     return name;
      //   },
      //   flex: 0.22,
      //   editable: false,
      //   filterOperators: stringOperators
      //   ,
      //   renderCell: (params) => {
      //     let name = params.row?.name || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      {
        field: "addOnProductName",
        headerName: "Name",
        minWidth: 250,
        valueGetter: (params) => {
          let name = params.row?.name || "N/A";
          return name
            .split(" ") // Split the name into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(" "); // Rejoin the words
        },
        flex: 0.22,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.name || "N/A";
          name = name
            .split(" ") // Split the name into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(" "); // Rejoin the words
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      

      {
        field: "action",
        headerName: "Actions ",
        width: 200,
        flex: 0.35,
        sortable: false,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          // let value = params.row?.schoolId
          return (
            <Stack direction={"row"} spacing={1}>
              <Tooltip title={"Edit Add-On Product List"}>
                <IconButton onClick={() => handleEdit(params)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
                <IconButton onClick={(e) => handleDelete(params.row.addOnListId, e)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        },
      },
    ];
    return result
  }

  const distributorColumn = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

    let result = [
      // {
      //   field: "AddOnProductName",
      //   headerName: "Name",
      //   minWidth: 250,
      //   valueGetter: (params) => {
      //     let name = params.row.name
      //     return name;
      //   },
      //   flex: 0.22,
      //   editable: false,
      //   filterOperators: stringOperators
      //   ,
      //   renderCell: (params) => {
      //     let name = params.row.name || "N/A";
      //     return (
      //       <Tooltip title={name || "undefined"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      {
        field: "SeriesName",
        headerName: "Series name",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        filterOperators: stringOperators,
        valueGetter: (params) => {
          let name = params.row.seriesName;
          return name
            ? name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" ")
            : "N/A";
        },
        renderCell: (params) => {
          let name = params.row.seriesName
            ? params.row.seriesName
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" ")
            : "N/A";
          return (
            <Tooltip title={name || "undefined"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      
      {
        field: "Condition",
        headerName: "Condition",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        filterOperators: stringOperators
        ,
        valueGetter: (params) => {
          // let name = params.row.condition
          let name = params.row.condition || "N/A";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
          // return name;
        },
        renderCell: (params) => {
          // let name = params.row.condition || "N/A";
          let name = params.row.condition || "N/A";
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
          return (
            <Tooltip title={name || "undefined"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "Amount",
        headerName: "Amount",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        filterOperators: stringOperators
        ,
        valueGetter: (params) => {
          let name = params.row.amount
          return name;
        },
        renderCell: (params) => {
          let name = params.row.amount || "N/A";
          return (
            <Tooltip title={name || "undefined"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "MinBookCount",
        headerName: "Min Book Count",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        filterOperators: stringOperators
        ,
        valueGetter: (params) => {
          let name = params.row.bookCount
          return name;
        },
        renderCell: (params) => {
          let name = params.row.bookCount || "N/A";
          return (
            <Tooltip title={name || "undefined"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "maxBookCount",
        headerName: "Max Book Count",
        minWidth: 250,
        flex: 0.35,
        editable: false,
        filterOperators: stringOperators
        ,
        valueGetter: (params) => {
          let name = params.row.maxCount
          return name;
        },
        renderCell: (params) => {
          let name = params.row.maxCount || "N/A";
          return (
            <Tooltip title={name || "undefined"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "Approve Order",
        headerName: "Actions ",
        width: 200,
        sortable: false,
        // editable: true,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {

          // let value = params.row?.distributorID

          return (
            <Stack direction={"row"} spacing={1}>

              <Tooltip title={"Edit Add-On Products"}>

                <IconButton onClick={() => handleEdit1(params)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>

              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="black"
                  onClick={(e) => handleDeleteDistributor(params.row.uniqueId, e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        },
      },
    ];
    return result
  }

  const handleDeleteDistributor = async (distributorId, event) => {
    event.stopPropagation();
    const confirmDelete = window.confirm("Are you sure you want to delete this AddOn Product ?");
    if (!confirmDelete) return;
    try {
      setLoading(true);
      const response = await window.Platform.database.deleteAddOnProducts({ id: distributorId });
      window.NotificationUtils.showSuccess("AddOn Product deleted successfully");
      fetchEmployees()
    } catch (error) {
      console.error("Error deleting the AddOn Product:", error);
      window.NotificationUtils.showError("Error deleting the AddOn Product");
    } finally {
      setLoading(false);
    }
  }

  const handleEdit1 = (data) => {
    console.log("editData", data)
    setEditAddOnNewProduct(true)
    setEditData1(data.row)
    console.log('dsf')
  }

  const handleEdit = (data) => {
    console.log("editData", data)
    setEditAddOnProduct(true)
    setEditData(data.row)
  }

  const handleDelete = async (schoolId, event) => {
    event.stopPropagation();
    const confirmDelete = window.confirm("Are you sure you want to delete this AddOn Product?");
    if (!confirmDelete) return;
    try {
      setLoading(true);
      const response = await window.Platform.database.deleteAddOnList({ id: schoolId });
      window.NotificationUtils.showSuccess("AddOn Product deleted successfully");
      fetchEmployees()
    } catch (error) {
      window.NotificationUtils.showError("Error deleting the AddOn Product");
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = async (value, keepSelected = false) => {
    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }
    setFilter(false);
    setSelectedTab(value);
  }

  const addNewSchool = () => {
    console.log("aaayaaa");
    
    setAddOnProductModalOpen(true)
    if (isMobile) {
      scrollToPreview();
    }
  }

  const addNewDistributor = () => {
    console.log("aaayaaa222");
    
    setAddOnProductNewModalopen(true)
    if (isMobile) {
      scrollToPreview();
    }
  }

  const fetchEmployees = async (data) => {
    setLoading(true);

    try {

      setVisibleEmployees([])
      let results
      if (selectedTab === 1) {
        results = await window.Platform.database.getAddOnProducttWeb({ pageNumber: paginationModel1.page });
        setRowCount1(results.data1)
      }

      else {
        results = await window.Platform.database.getAddOnListWeb({ pageNumber: paginationModel.page });
        setRowCount(results.data1)
        // setRowCount(results.data.length)
      }
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1, uniqueId:obj.id }));
      setVisibleEmployees(jsonArrayWithId);
      setLoading(false);
      // window.NotificationUtils.showError(results.message)
      if (results.status) {
        setLoading(false);
        window.NotificationUtils.showError(results.message)
      }
      else {
        setLoading(false);
        // window.NotificationUtils.showSuccess(results.message)
      }
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  }

  const scrollToPreview = () => {
    window.scrollTo(0, document.getElementById("detailsPreviewContainer").getBoundingClientRect().top);
  }

  function CustomToolbar() {

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "users-report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderTabContent = () => {
    if (selectedTab === 0) {
      return (
        <DataGridContainer style={{ height: !visibleEmployees.length ? '200px' : 'auto' }}>
          <DataGrid
            className="payrollGrid"
            density='compact'
            rows={visibleEmployees || []}
            columns={schoolColumns()}
            disableSelectionOnClick
            disableRowSelectionOnClick
            pageSizeOptions={[10]}
            onRowClick={(rowParams, event) => {
              setUserModal(rowParams.row)
            }}
            rowHeight={60}
            onFilterModelChange={(val) => {
              console.log("vvvv", val);
              fetchEmployees()
              setFilter(false)
              setSearchTerm(null)
            }
            }
            filterMode="server"
            rowCount={rowCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      );
    }
    else if (selectedTab === 1) {
      return (
        <DataGridContainer style={{ height: !visibleEmployees.length ? '200px' : 'auto' }}>
          <DataGrid
            className="payrollGrid"
            density='compact'
            rows={visibleEmployees || []}
            columns={distributorColumn()}
            disableSelectionOnClick
            disableRowSelectionOnClick
            pageSizeOptions={[10]}
            onRowClick={(rowParams, event) => {

              setUserModal(rowParams.row)
              console.log(rowParams.onRowClick)
            }}
            rowHeight={60}
            onFilterModelChange={(val) => {
              fetchEmployees()
              setFilter(false)
              setSearchTerm1(null)
            }
            }
            filterMode="server"
            rowCount={rowCount1}
            paginationModel={paginationModel1}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel1}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      );
    }
  }

  const onClose = async (data) => {
    try {
      setOtpModalData(data)
      submitOtp(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  const onCloseDistributor = async (data) => {
    try {
      setOtpModalDataDistributor(data)
      submitOtpDistributor(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  const submitOtp = async (data) => {
    try {
      setLoading(true)
      const response1 = await window.Platform.database.saveAddOnList(data)
      if (response1.status === true) {
        window.NotificationUtils.showError(response1.message);
        setLoading(false)
        return;
      }
      setAddOnProductModalOpen(false)
      setLoading(false)
      fetchEmployees()
      window.NotificationUtils.showSuccess("Add-On Product saved Successfully");
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const submitOtpDistributor = async (data) => {
    try {
      setLoading(true)
      const results = await window.Platform.database.saveaddOnProducts(data);
      if (results.status === true) {
        window.NotificationUtils.showError(results.message);
        setLoading(false)
        return;
      }
      setAddOnProductNewModalopen(false)
      setLoading(false)
      fetchEmployees()
      window.NotificationUtils.showSuccess("Add-On products saved successfully");
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  return (
    <>
      <StaffEditorPageContainer>{console.log(selectedTab,"selectedTab")}
        <HeaderContainer>
          <Stack spacing={1} direction={"row"}>
            <Button variant="contained" color="primary"
              onClick={selectedTab === 0 ? addNewSchool : addNewDistributor} sx={{ mr: 1, borderRadius: '10px' }}>
              <Add sx={{ mr: 1 }} />
              {selectedTab === 0 ? "Add On List" : "Add On Product"}
            </Button>
          </Stack>
        </HeaderContainer>
        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Add-On Product List" />
              {<Tab label="Add-On Products" />}
            </StyledTabs>
            {loading && <OpaqueLoading />}
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {
        addOnProductModalOpen && <AddOnProductModal onClose1={(data) => onClose(data)} onClose={() => setAddOnProductModalOpen(false)} fetchData={() => fetchEmployees()} status={true} />
      }
      {
        addOnProductNewModalopen && <AddOnProductNewModal onClose={() => setAddOnProductNewModalopen(false)} onClose1={(data) => onCloseDistributor(data)} fetchData={() => fetchEmployees()} status={true} />
      }
      {
        editAddOnProduct && <AddOnProductModal onClose={() => setEditAddOnProduct(false)} fetchData={() => fetchEmployees()} dataProp={editData} status={false} />
      }
      {
        editAddOnNewProduct && <AddOnProductNewModal onClose={() => setEditAddOnNewProduct(false)} fetchData={() => fetchEmployees()} dataProp={editData1} status={false} />
      }
    </>
  );
}

export default AddOnProducts;