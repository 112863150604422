import { useEffect, useState } from "react";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Typography, Button, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Add } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import AddIncentiveModal from '../../components/modals/AddIncentiveModal'
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative;`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};
  ${isMobile &&
    `
    flex-direction: column;
  `}
`;

export default function IncentiveManagement() {
    const [loading, setLoading] = useState(false);
    const [incentiveModal, setIncentiveModal] = useState(false);
    const [editIncentiveModal, setEditIncentiveModal] = useState(false);
    const [dataModal, setDataModal] = useState();
    const [rowCount, setRowCount] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationModel, setPaginationModel] = useState({
        page: currentPage - 1,
        pageSize: 11,
    });

    useEffect(() => {
        getIncentiveList();
    }, [paginationModel.page]);

    const formatTimeStamp = (timestampFromDB) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };
        const formattedDate = new Date(timestampFromDB).toLocaleString("en-US", options);
        return formattedDate;
    };

    const getIncentiveList = async () => {
        setLoading(true);
        try {
            let results = await window.Platform.database.getincentivelist({ pageNumber: paginationModel.page});
            
            setRowCount(results.totalCount); 
            setDataList(results.data); 
            setLoading(false); 
      // window.NotificationUtils.showError(results.message)
        } catch (error) {
            window.NotificationUtils.showError("Data not found");
            setLoading(false);
        }
    };

    // const deleteIncentive1 = async (id) => {
    //     try {
    //         setLoading(true);
    //         await window.Platform.database.deleteIncentive({ id });
    //         getIncentiveList();
    //         setLoading(false);
    //         window.NotificationUtils.showSuccess("Incentive deleted successfully!");
    //     } catch (error) {
    //         console.error(error);
    //         setLoading(false);
    //     }
    // };

    const deleteIncentive = async (id)=> {
    
        const confirmDelete = window.confirm("Are you sure you want to delete this Incentive ?");
        if (!confirmDelete) return;
    
        try {
          setLoading(true);

          const response = await window.Platform.database.deleteIncentive({ id });
    
    
          window.NotificationUtils.showSuccess("Incentive deleted successfully");

          getIncentiveList();
          
        } catch (error) {
          console.error("Error deleting the Incentive:", error);
          window.NotificationUtils.showError("Error deleting the Incentive");
        } finally {
          setLoading(false);
        }
    }

    const handleEdit = (data) => {
        setDataModal(data);
        setEditIncentiveModal(true);
        setIncentiveModal(false);
    };

    const getIncentiveColumns = () => {
        const stringOperators = getGridStringOperators().filter((op) => ["contains"].includes(op.value));

        return [
            {
                field: "role",
                headerName: "Role",
                sortable: false,
                width: 200,
                filterOperators: stringOperators,
                editable: false,
                valueGetter: (params) => params.row?.role || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.role || "N/A"}>
                        <Typography variant="inherit">{params.row?.role || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "minAmount",
                headerName: "minAmount",
                width: 350,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.minAmount || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.minAmount || "N/A"}>
                        <Typography variant="inherit">{params.row?.minAmount || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "maxAmount",
                headerName: "maxAmount",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.maxAmount || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.maxAmount || "N/A"}>
                        <Typography variant="inherit">{params.row?.maxAmount || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "incentivePercent",
                headerName: "incentivePercent",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.incentivePercent || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.incentivePercent || "N/A"}>
                        <Typography variant="inherit">{params.row?.incentivePercent || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "target",
                headerName: "target",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.target || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.target || "N/A"}>
                        <Typography variant="inherit">{params.row?.target || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "createdAt",
                headerName: "createdAt",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => formatTimeStamp(params.row?.createdAt) || "N/A",
                renderCell: (params) => (
                    <Tooltip title={formatTimeStamp(params.row?.createdAt) || "N/A"}>
                        <Typography variant="inherit">{formatTimeStamp(params.row?.createdAt) || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "updatedAt",
                headerName: "updatedAt",
                width: 250,
                filterable: true,
                sortable: false,
                valueGetter: (params) => formatTimeStamp(params.row?.updateAt) || "N/A",
                renderCell: (params) => (
                    <Tooltip title={formatTimeStamp(params.row?.updateAt) || "N/A"}>
                        <Typography variant="inherit">{formatTimeStamp(params.row?.updateAt) || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 250,
                filterable: false,
                sortable: false,
                disableExport: true,
                renderCell: (params) => (
                    <>
                        <Tooltip title="Edit Incentive">
                            <IconButton onClick={() => handleEdit(params.row)}>
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Incentive">
                            <IconButton onClick={() => deleteIncentive(params.row?.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            },
        ];
    };

    const renderContent = () => {
        return (
            <DataGridContainer>
                <DataGrid
                    className="payrollGrid"
                    density="compact"
                    rows={dataList || []}
                    columns={getIncentiveColumns()}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    rowCount={rowCount}
                    pageSizeOptions={[10]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </DataGridContainer>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    csvOptions={{
                        fileName: "export_Incentive_Data",
                    }}
                />
            </GridToolbarContainer>
        );
    }

    const addIncentive = () => {
        setIncentiveModal(true);
    };

    return (
        <>
            <StaffEditorPageContainer>
                {loading && <OpaqueLoading />}
                <HeaderContainer>
                          <div
                            style={{
                              width: "60%",
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          ></div>
                          <Stack spacing={1} direction={"row"}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addIncentive()}
                              sx={{ mr: 1, borderRadius: "10px" }}
                            >
                              <Add sx={{ mr: 1 }} />
                              Add Incentive
                            </Button>
                          </Stack>
                        </HeaderContainer>
                <TableContainer>{renderContent()}</TableContainer>
            </StaffEditorPageContainer>
            {incentiveModal && (
                <AddIncentiveModal
                    onClose={() => setIncentiveModal(false)}
                    fetchData={() => getIncentiveList()}
                />
            )}
            {editIncentiveModal && (
                <AddIncentiveModal
                    onClose={() => setEditIncentiveModal(false)}
                    fetchData={() => getIncentiveList()}
                    props={dataModal}
                />
            )}
        </>
    );
}
