import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Add } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import SchoolCategoryModal from "../../components/modals/PicklistModals/schoolCategoryModal";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import RemarkModal from "../../components/modals/RemarkModal";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: calc(100% );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

export default function RemarkComponent() {

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [visitModal, setVisitModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [dataModal, setDataModal] = useState();

  useEffect(() => {
    getRemarkInPicklist();
  }, []);

  // const getRemarkInPicklist = async () => {
  //     setLoading(true);
  //   try {
  //     // let results1 = await window.Platform.database.testApi();

  //     let results = await window.Platform.database.getRemarkInPicklist();
  //     console.log("results", results);
  //     // if(auth?.user?.role === ROLES.ADMIN){
  //     const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

  //     setAccessChanges(jsonArrayWithId);
  //     // }
  //     // setVisibleEmployees(results);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("ERRORCHECK", error);
  //     window.NotificationUtils.showError("Error Fetching Data");
  //     setLoading(false);
  //   }
  //   finally {
  //     setLoading(false)
  //   }
  // }
  const getRemarkInPicklist = async () => {
    setLoading(true);
    try {
      let results = await window.Platform.database.getRemarkInPicklist();
      console.log("results", results);
  
      // Ensure database ID is used correctly
      const jsonArrayWithId = results?.data?.map((obj) => ({ 
        ...obj, 
        dbId: obj.id // Preserve the actual DB ID
      }));
  
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK", error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };
  // const handleDelete = async (data) => {
  //   const isConfirmed = window.confirm("Are you sure you want to delete this Remark Prodduct?");

  //   if (!isConfirmed) {
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     let results = await window.Platform.database.deleteRemarkInPicklist({ id: data });
  //     window.NotificationUtils.showSuccess("Remark deleted successfully!");
  //     setLoading(false);
  //     getRemarkInPicklist();
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async (data) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Remark Product?");
    if (!isConfirmed) return;
  
    try {
      setLoading(true);
      await window.Platform.database.deleteRemarkInPicklist({ id: data });
      window.NotificationUtils.showSuccess("Remark deleted successfully!");
      getRemarkInPicklist();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  

  // const getRemarkColumns = () => {
  //   let result = [
  //     {
  //       field: "RemarkName",
  //       headerName: "Name",
  //       width: 200,
  //       sortable: false,
  //       valueGetter: (params) => {
  //         return (params.row?.name || "N/A");
  //       },
  //       renderCell: (params) => {
  //         let name = params.row.name || 'N/A';
  //         return (
  //           <Tooltip title={name || "N/A"}>
  //             <Typography variant="inherit">{name}</Typography>{console.log(params,"llll")}
  //           </Tooltip>
  //         )
  //       },
  //     },
  //     {
  //       field: "actions",
  //       headerName: "Actions",
  //       editable: false,
  //       hide: true,
  //       minWidth: 150,
  //       renderCell: (params) => {
  //         return (
  //           <>
  //             <Tooltip title={"Update"}>
  //               <IconButton onClick={() => handleEdit(params.row)}>
  //                 <DriveFileRenameOutlineIcon />
  //               </IconButton>
  //             </Tooltip>
  //             <Tooltip title={"Delete"}>
  //               <IconButton onClick={() => handleDelete(params.row.id)}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             </Tooltip>
  //           </>
  //         )
  //       },
  //     }
  //   ];
  //   return result;
  // }
  const getRemarkColumns = () => [
    {
      field: "RemarkName",
      headerName: "Name",
      width: 200,
      sortable: false,
      valueGetter: (params) => params.row?.name || "N/A",
      renderCell: (params) => (
        <Tooltip title={params.row?.name || "N/A"}>
          <Typography variant="inherit">{params.row?.name || "N/A"}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      editable: false,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Update">
            <IconButton onClick={() => handleEdit(params.row)}>
              <DriveFileRenameOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDelete(params.row.dbId)}> {/* Use dbId instead of id */}
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    }
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridContainer>
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={accessChanges || []}
            columns={getRemarkColumns()}
            rowHeight={60}
            disableSelectionOnClick
            disableRowSelectionOnClick
            autoPageSize
            components={{
              Toolbar: CustomToolbar,
            }}
            pageSizeOptions={[10]}
          />
        </DataGridContainer>
      </Box>
    );
  };

  const addVisit = () => {
    setVisitModal(true);
  }

  const handleEdit = (data) => {
    setEditModal(true);
    setDataModal(data);
  }

  return (
    <>
      {isMobile && (
        <Drawer />
      )}
      <StaffEditorPageContainer>
        {loading && <OpaqueLoading />}
        <HeaderContainer>
          <div>
          </div>

          <Stack direction={"row"} spacing={2} >
            <Button variant="contained" color="primary"
              onClick={() => addVisit()} sx={{ mr: 1, borderRadius: '10px' }}>
              <Add sx={{ mr: 1 }} />
              Add New
            </Button>
          </Stack>

        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
        visitModal &&
        <RemarkModal
          onClose={() => setVisitModal(false)}
          loading={loading}
          fetchData={() => getRemarkInPicklist()}
          status={true}
        />
      }
      {
        editModal &&
        <RemarkModal
          onClose={() => setEditModal(false)}
          loading={loading}
          fetchData={() => getRemarkInPicklist()}
          status={false}
          propData={dataModal}
        />
      }

    </>
  );
}
