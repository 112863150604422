import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { CodeSandboxCircleFilled } from "@ant-design/icons";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const AddOnProductNewModal = ({ onClose, dataProp, fetchData }) => {

  const [form, setForm] = useState({ seriesCategoryId: dataProp ? dataProp?.seriesTableId : "", name: "", id: null });
  const [loading, setLoading] = useState(false);
  const [addOnProducts, setAddOnProducts] = useState([]);
const [seriesCategories, setSeriesCategories] = useState([]);
  const [condition, setCondition] = useState(dataProp?.condition || "");
  const [amount, setAmount] = useState(dataProp?.amount || null);
  const [bookCount, setBookCount] = useState(
    dataProp?.condition === "book" ? dataProp?.bookCount || 1 : dataProp?.condition === "set" ? dataProp?.bookCount : null
  );
  const [maxCount, setMaxCount] = useState(
    dataProp?.condition === "set" ? null : dataProp?.maxCount || null
  );
  useEffect(() => {
    console.log(dataProp,"dataPropdataProp")
  if(dataProp){
    setForm({ ...form, name: dataProp?.name, id: dataProp?.uniqueId });
  }
    getSeriesCategoryData();
  }, []);

  const getSeriesCategoryData = async () => {
    let results = await window.Platform.database.getSeriesCategory();
    setSeriesCategories(results.data);
  };

console.log(form,"form=?>>")
  const handleSubmit = async () => {
    if(!seriesCategories || !amount || !condition){
      window.NotificationUtils.showError("Please Fill All Details");
      return;
    }
    else if(condition == "book" && !maxCount){
      window.NotificationUtils.showError("Please Fill Max Book Count");
      return;
    }
    else if(condition == "set" && !bookCount){
      window.NotificationUtils.showError("Please Fill Min Boook Count");
      return;
    }
   
    setLoading(true);
    try {
      if(dataProp){

        await window.Platform.database.editAddOnProducts({ 
            id: dataProp.uniqueId,
            seriesCategoryId: form.seriesCategoryId,
            name: form.name,
            product_id:form.name,
            condition,
            amount,
            bookCount,
            maxCount,
         });
        window.NotificationUtils.showSuccess("Add-On Product Updated successfully");
  
        setAddOnProducts(prev => [...prev, { name: form.name, id: form.id }]);
       
      }
      else{
       
        await window.Platform.database.saveaddOnProducts( {
            id: form.id,
            product_id:form.id,
            maxCount:maxCount,
            bookCount:bookCount,
            amount:amount,
            condition:condition,
            name:form.id
        } );
        window.NotificationUtils.showSuccess("Add-On Product added successfully");
  
        setAddOnProducts(prev => [...prev, { name: form.name, id: form.id }]);
        
      }

      setForm({ seriesCategoryId: "", name: "", id: null });
      onClose();
      fetchData();
      setLoading(false);
    } catch (err) {
      window.NotificationUtils.showError(err?.message);
    }
    setLoading(false);
  };

  const handleSeriesChange = (value) => {
    setForm((prev) => ({ ...prev, seriesCategoryId: value }));
    const selectedSeries = seriesCategories.find((item) => item.seriesTableId === value);
    if (selectedSeries) {
      const newId = selectedSeries.addOnProduct ? selectedSeries.addOnProduct : Math.floor(1000000000 + Math.random() * 9000000000);
      setForm((prev) => ({
        ...prev,
        id: newId
      }));
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open>
    <StyledDialogContent>
    <ModalHeaderContainer>
          <Typography variant="h5">{dataProp ? "Edit Add-On Product" : "Add Add-On Product"}</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {loading && <OpaqueLoading />}
         
      <Stack gap={2} padding={2}>
      <FormControl fullWidth>
                    <InputLabel>Select Series</InputLabel>
                    <Select
                    disabled={dataProp}
                      value={form.seriesCategoryId}
                      onChange={(e) => handleSeriesChange(e.target.value)}
                    >
                      {/* {seriesCategories?.map((option) => (
                        <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                          {option.seriesName}
                        </MenuItem>
                      ))} */}
                        {seriesCategories?.map((option) => (
                          <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                            {option.seriesName.charAt(0).toUpperCase() + option.seriesName.slice(1).toLowerCase()}
                          </MenuItem>
                        ))}
                        
                    </Select>
                  </FormControl>
        {/* <FormControl>
          <InputLabel>Select Condition</InputLabel>
          <Select value={condition} onChange={(e) => setCondition(e.target.value)}>

            <MenuItem value="book">
              Book
            </MenuItem>
            <MenuItem value="set">
              Set
            </MenuItem>
          </Select>
        </FormControl> */}
        <FormControl>
  <InputLabel>Select Condition</InputLabel>
  <Select
    value={condition}
    onChange={(e) => {
      const selectedCondition = e.target.value;
      setCondition(selectedCondition);

      // Reset values based on the selected condition
      if (selectedCondition === "set") {
        setMaxCount(null); // Reset maxCount for "set"
      } else if (selectedCondition === "book") {
        setBookCount(1); // Optionally set default bookCount for "book"
      }
    }}
  >
    <MenuItem value="book">Book</MenuItem>
    <MenuItem value="set">Set</MenuItem>
  </Select>
</FormControl>

        <TextField
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
        />
        <TextField
          label="Min Book Count"
          type="number"
          value={bookCount}
          onChange={(e) => setBookCount(e.target.value)}
          fullWidth
        />
        {condition === "book" && (
          <TextField
            label="Max Book Count"
            type="number"
            value={maxCount}
            onChange={(e) => setMaxCount(e.target.value)}
            fullWidth
          />
        )}

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {dataProp ? "Update" : "Submit"}
        </Button>
      </Stack>
    </StyledDialogContent>
  </Dialog>
  );
};

export default AddOnProductNewModal;
