import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
    Dialog,
    DialogContent,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkAddSchool = ({onClose,fetchData}) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const [assignedToData, setAssignTodata] = useState([])
  const [boardData, setBoardData] = useState([])
  const [mediumData, setMediumData] = useState([])
  const [gradeData, setGradeData] = useState([])
  const [contactPersonRole, setContactPersonRole] = useState([])
  const [schoolCategories, setSchoolCategories] = useState([])
  const [distributor, setDistributor] = useState([])
  const hiddenFileInput = useRef(null);
    const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueDistributor, setSelectedValueDistributor] = useState('');
useEffect(()=>{
  fetchEmployees()
  getBoard()
  getMedium()
  getContactPersonRole()
  getGrade()
  fetchDistributors()
  // getSchoolCategories();
},[])


const fetchEmployees=async()=>{
  try{
  setLoading(true)
 const results = await window.Platform.database.getUsersForWork();
 setAssignTodata(results?.data)
 console.log(results.data,"Assign To data ")
}
catch(err){
  console.log(err)
}
}
const getBoard=async(data)=>{
  let results = await window.Platform.database.getBoard();
  setBoardData(results.data)
}
const getMedium=async(data)=>{
  let results = await window.Platform.database.getMedium();
  setMediumData(results.data)
}

const getGrade=async(data)=>{
  try{
    setLoading(true)
    const results = await window.Platform.database.getGrade();
    setGradeData(results.data)
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)

  }
}

const getContactPersonRole=async(data)=>{
  try{
    setLoading(true)
    const results = await window.Platform.database.getContactPersonRole();
    setContactPersonRole(results.data)
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)

  }
}

const getSchoolCategories = async () => {
  try {

    setLoading(true);
    let results = await window.Platform.database.getSchoolCategories();
    setSchoolCategories(results.data);
    setLoading(false);

  } catch (error) {
    window.NotificationUtils.showError("Error Fetching Data");
    setLoading(false);
  }
};

const fetchDistributors=async()=>{
  try{
    setLoading(true)
  const results = await window.Platform.database.getDistributor();
  setDistributor(results.data)
 
  setLoading(false)

}catch(err){
  setLoading(false)

  console.log(err)
}

}


  const getRowClassName = (params) => {
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{
            fileName: 'bulk-upload-Supplier',
          }}
        />
      </GridToolbarContainer>
    );
  }

  // const getEmployeeName = (
  //   employeeCode,
  //   reportingManagerStringNeeded = false
  // ) => {
  //   let result = "Admin";
  //   let employee = props.staffMembers.find(
  //     (member) => member.employeeCode === employeeCode
  //   );
  //   if (reportingManagerStringNeeded && employee) {
  //     employee = props.staffMembers.find(
  //       (member) => member.employeeCode === employee.reportingManager
  //     );
  //   }
  //   if (employee) {
  //     let name = `${employee.firstName} ${
  //       employee.lastName ? employee.lastName : ""
  //     }`;
  //     if (!reportingManagerStringNeeded) {
  //       result = name;
  //     } else {
  //       result = `${name}-${employee.employeeCode} (${employee.role})`;
  //     }
  //   }
  //   return result;
  // };
    const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const newAssignedToData = assignedToData?.map((ele, ind)=>{
  //   // const tosend =ele.name
  // const tosend =`${ele.name}/${ele.id}`
  //   return tosend
  // })

  const newBoardData = boardData?.map((ele, ind)=>{
    const tosend =ele.boardName
  // const tosend =`${ele.boardName}/${ele.boardId}`
    return tosend
  })

  const newMediumData = mediumData?.map((ele, ind)=>{
    const tosend =ele.mediumName
  // const tosend =`${ele.mediumName}/${ele.mediumTableId}`
    return tosend
  })

  const newContactPersonRole = contactPersonRole?.map((ele, ind)=>{
    const tosend =ele.roleName
  // const tosend =`${ele.roleName}/${ele.contactPersonRoleId}`
    return tosend
  })

  const newGradeData = gradeData?.map((ele, ind)=>{
    // const tosend =ele.gradeName
  const tosend =`${ele.gradeName}`
    return tosend
  })


  const handleDownload = async ( ) => {
    try
   {
    // const headers =['schoolName', 'parentSchoolName', "board", "medium", "decisionMakerName", "decisionMakerContact", "decisionMakerRole", "email", "website", "grade", "strength", "schoolId", "gstNo", "registrationNo", "affiliation/pan No", "bankName", "accountHolderName", "accountNo", "ifscCode" , "branchname", "pincode", "addressLine1"]
    const headers =['schoolName', 'parentSchoolName', "board", "medium", "decisionMakerName", "decisionMakerContact", "decisionMakerRole", "email", "website", "grade", "strength", "gstNo", "registrationNo", "affiliation/pan No", "bankName", "accountHolderName", "accountNo", "ifscCode" , "branchname", "pincode", "addressLine1","distributorName", "party_id", "clusterName"]
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const board = [newBoardData]
    const medium = [newMediumData]
    const decisionMakerRole = [newContactPersonRole]
    const grade = [newGradeData]
    // const schoolId = [newDistributor]

    
    worksheet.addRow(headers);
    const numRows = 101;
    const getColumnLetter = (col) => {
      let letter = '';
      while (col > 0) {
        let temp = (col - 1) % 26;
        letter = String.fromCharCode(temp + 65) + letter;
        col = (col - temp - 1) / 26;
      }
      return letter;
    };
    const numberOfOptions = 13; 
    for (let e = 2; e <= numRows; e++) {
      for (let f = 1; f <= numberOfOptions; f++) {
       const textBoxCol = getColumnLetter(6 + (f - 1) * 2); 
           const textBoxCell = worksheet.getCell(`${textBoxCol}${e}`);

        worksheet.getCell(`C${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${board.join(',')}"`],
        };
        worksheet.getCell(`D${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${medium.join(',')}"`],
        };
        worksheet.getCell(`G${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${decisionMakerRole.join(',')}"`],
        };
        worksheet.getCell(`J${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${grade.join(',')}"`],
        };
        // worksheet.getCell(`L${e}`).dataValidation = {
        //   type: 'list',
        //   formulae: [`"${schoolId.join(',')}"`],
        // };
      }
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'schoolAdd.xlsx';
      a.click();
      URL.revokeObjectURL(url);
      setLoading(false)
    });
  }
  catch (error) {
    console.error(error);
    setLoading(false)
  }
//     const allData = [
//       {
//         "schoolName": "Green Valley School",
//         "parentSchoolName": "Valley Educational Trust",
//         "board": "CBSE",
//         "medium": "English",
//         "decisionMakerName": "John Doe",
//         "decisionMakerContact": "82271262621",
//         "decisionMakerRole": "Principal",
//         "email": "john.doe@greenvalley.edu",
//         "website": "www.greenvalley.edu",
//         "grade": "10th",
//         "strength": 500,
//         "schoolId": "XYZ12345",
//         "gstNo": "29ABC1234XYZ1A1",
//         "registrationNo": "ABC12345",
//         "affiliation/pan No": "ABCD123456",
//         "bankName": "State Bank of India",
//         "accountHolderName": "Green Valley School",
//         "accountNo": "9876543210",
//         "ifscCode": "SBIN0001234",
//         "branchname": "Main Branch",
//         "pincode": "560001",
//         "addressLine1": "123, Green Valley Road, Bangalore"
//       }
//     ]    
     
//     setLoading(true)

//     const selectedColumns = [
//       'schoolName',             // School name
//       'parentSchoolName',       // Parent school name
//       'board',                  // Education board (e.g., CBSE, ICSE)
//       'medium',                 // Medium of instruction (e.g., English)
//       'decisionMakerName',      // Name of the decision maker
//       'decisionMakerContact',   // Contact number of the decision maker
//       'decisionMakerRole',      // Role of the decision maker (e.g., Principal, Headmaster)
//       'email',                  // Email address of the decision maker
//       'website',                // Website of the school
//       'grade',                  // Grade or class (e.g., 10th, 12th)
//       'strength',               // Total number of students
//       'schoolId',          // Distributor ID
//       'gstNo',                  // GST number
//       'registrationNo',         // Registration number
//       'affiliation/pan No',     // Affiliation or PAN number
//       'bankName',               // Bank name
//       'accountHolderName',      // Account holder name
//       'accountNo',              // Bank account number
//       'ifscCode',               // IFSC code of the bank
//       'branchname',             // Branch name of the bank
//       'pincode',                // Pincode (postal code)
//       'addressLine1'            // Address line 1 (e.g., street address)
//     ];    

// const filteredData = allData.map(item =>
//   Object.fromEntries(Object.entries(item).filter(([key]) => selectedColumns.includes(key)))
// );
//    const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
//     XLSX.writeFile(workbook, `schoolAdd.xlsx`);
//     setLoading(false)
//     }

    
//     catch (error) {
//       console.error(error);
//       setLoading(false)
//     }

  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    setUploaded(false);
    handleImport(file);
    setFileKey((prevKey) => prevKey + 1);
  };
    const handleImport = (file) => {
      try {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
  
        const workbook = XLSX.read(data, { type: "array" });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = jsonData.slice(1).map((row, index) => ({
          assignedTo : selectedValue,
          // schoolId:selectedValueDistributor,
            schoolName: row[0],
            parentSchoolName: row[1],
            board: row[2],
            medium: row[3],
            makerName: row[4],
            makerContact: row[5]?.toString(),
            makerRole:row[6],
            email: row[7],
            website: row[8],
            grade: row[9],
            strength: row[10],
            // schoolId:row[11],
            gstNo:row[11],
            registrationNo:row[12],
            affiliationPan:row[13],
            bankName: row[14],
            holderName:row[15],
            accountNo:row[16]?.toString(),
            ifsc:row[17],
            branchName:row[18],
            pincode:row[19]?.toString(),
            addressLine1:row[20],
            distributorName:row[21],
            party_id:row[22],
            clusterName:row[23],
          id: index + 1,
        }));
          console.log("formattedData",formattedData)
        setTargetRows(formattedData)
        setLoading(false);
  
      };
      reader.readAsArrayBuffer(file);
    }}
    catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };




  const getGridColumns = () => {
      let columns = [
        {
            field: "id",
            headerName: "S.no",
            maxWidth: 100,
            valueGetter: (params) => {
              let name = params.row.id || "";
              return name;
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              let name = params.row.id || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "assignTo",
            headerName: "AssignTo",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.assignedTo
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              console.log("RESPONSE============",params)
              let name=params.row.assignedTo
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "name",
            headerName: "School Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.schoolName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.schoolName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "board",
            headerName: "Board",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.board
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.board
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "medium",
            headerName: "medium",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.medium
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.medium
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "makerName",
            headerName: "Decision Maker Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.makerName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.makerName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
         
          ,
          {
            field: "makerContact",
            headerName: "Decision Maker Contact",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.makerContact
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.makerContact
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "makerRole",
            headerName: "Decision Maker Role",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.makerRole
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.makerRole
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.email
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.email
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "website",
            headerName: "website",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.website
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.website
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "grade",
            headerName: "grade",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.grade
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.grade
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "strength",
            headerName: "strength",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.strength
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.strength
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          // {
          //   field: "schoolId",
          //   headerName: "schoolId",
          //   minWidth: 200,
          //   valueGetter: (params) => {
          //    let name=params.row.schoolId
          //    return name
          //   },
          //   flex: 0.22,
          //   editable: false,
          //   renderCell: (params) => {
          //     // console.log("RESPONSE",params)
          //     let name=params.row.schoolId
          //     return (
          //       <Tooltip title={name}>
          //         <Typography variant="inherit">{name}</Typography>
          //       </Tooltip>
          //     );
          //   },
          // }
          // ,
          {
            field: "gstNo",
            headerName: "Gst No",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.gstNo
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.gstNo
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "registrationNo",
            headerName: "School Registration No",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.registrationNo
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.registrationNo
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "affiliationPan",
            headerName: "School Affiliation / Pan",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.affiliationPan
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.affiliationPan
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "bankName",
            headerName: "Bank Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.bankName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.bankName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "holderName",
            headerName: "Account Holder Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.holderName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.holderName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "accountNo",
            headerName: "Account No",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.accountNo
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.accountNo
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "ifsc",
            headerName: "ifsc ",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.ifsc
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.ifsc
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "branchName",
            headerName: "Branch Name ",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.branchName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.branchName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "pincode",
            headerName: "Pincode",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.pincode
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.pincode
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
          ,
          {
            field: "addressLine1",
            headerName: "Address Line1",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.addressLine1
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.addressLine1
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "distributorName",
            headerName: "distributorName",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.distributorName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.distributorName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "party_id",
            headerName: "party_id",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.party_id
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.party_id
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "clusterName",
            headerName: "Cluster Name",
            minWidth: 200,
            valueGetter: (params) => {
             let name=params.row.clusterName
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.clusterName
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          }
         
      ];
      if (uploaded) {
        columns.splice(
          0,
          0,
          {
            field: "status",
            headerName: "Upload Status",
            width: 100,
            renderCell: (params) => {
                // console.log("PARAMSINDATA",params)
              let name = "";
              if (params.row.status) {
                name =
                  params.row.status === "Success" ? "Success" : "Failure";
              }
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>{console.log(params.row,"params.row")}
                </Tooltip>
              );
            },
            editable: false,
          },
          {
            field: "reason",
            headerName: "Reason",
            width: 200,
            renderCell: (params) => {
              let name = params.row.reason || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
            editable: false,
          }
        );
      }
      return columns;
  }

  const getFileRows = async (file) => {
    try {
      let data = await file.arrayBuffer();
      const wb = XLSX.read(data, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let dataParse = XLSX.utils.sheet_to_json(ws);
      if(dataParse.length > 0){
        dataParse = dataParse.map((entry, index) => {
          return {
            ...entry,
            id: index,
          };
        });
      }
      let element = document.getElementById("fileUploadPicker");
      if (element) {
        element.value = "";
      }
      return dataParse;
    } catch (error) {
      throw error;
    }
  };

  const handleUpload = async () => {
    try {
      let uploadInput = document.getElementById("filePickerButton");
      if (uploadInput) {
        if (uploadInput.files.length > 0) {
          let file = uploadInput.files[0];
          setLoading(true);
          let response = await getFileRows(file);
          // console.log("RESPONSE",response)
          if (response.length > 0) {
            setTargetRows(response);
            setUploaded(false);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

  const hasErrors = () => {
    try {
      if (!targetRows) {
        window.NotificationUtils.showError(
          "Please select a valid file for upload"
        );
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const dataRowCheck = (rowDetails) => {
    try {
      let result = false;
      if (!rowDetails.employeeCode) {
        // result = BULK_UPLOAD_FAILURE_CODES.MISSING_EMPLOYEE_CODE;
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

    const handleRowSelection = (selection) => {
    // console.log("SELECT",selection)
    // console.log("selection",rows)
    let array=[]
    let data=targetRows.filter((row) => selection.includes(row.id));
    let finalData=data.map((row) => {
      let sent={
        assignedTo : row.assignedTo,schoolName:row.schoolName,parentSchoolName:row.parentSchoolName,id:row.id,
        board:row.board,medium:row.medium,makerName:row.makerName,makerContact:row.makerContact,
        makerRole:row.makerRole,email:row.email,website:row.website,grade:row.grade,strength:row.strength,
        schoolId:row.schoolId,gstNo:row.gstNo,registrationNo:row.registrationNo,affiliationPan:row.affiliationPan,
        bankName:row.bankName,holderName:row.holderName,accountNo:row.accountNo,ifsc:row.ifsc,branchName:row.branchName,pincode:row.pincode,
        addressLine1:row.addressLine1,distributorName:row.distributorName, party_id:row.party_id, clusterName:row.clusterName
      }
      array.push(sent)

    })
  
    // console.log("selection1",finalData)
    // setSelectedRows(finalData);
    return array;
  };

  const onClickUpload = async () => {
    try {
   const data=handleRowSelection(selectedRowIds)
   console.log("SELECTED ROWS",data)
    let finalData =[...targetRows]
    console.log("SELECTED ROWS",selectedRowIds);
    console.log("SELECTED ROWS FINALLY",finalData)
      setLoading(true);
      let response = await window.Platform.database.addSchoolBulk(data);
      console.log("SUCCESS",response)
      setTargetRows(response.data)
       
    //   let newTargetRows = [...newRows];
    console.log("FINAL",finalData)
    setSelectedRowIds([])
      for(let responseEntry of response.data){
        let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
        // console.log("ROWINDEX",rowIndex)
        if(rowIndex !== -1){
            finalData[rowIndex].status = responseEntry.status;
            finalData[rowIndex].reason = 'N/A';
            // console.log("CHECK!")
          if(responseEntry.status === 'Failure'){
            finalData[rowIndex].reason = responseEntry.reason;
          }
        }
      }
      setTargetRows(finalData);
      if (!uploaded) {
        setUploaded(true);
      }
      setLoading(false);

      if (response.status) {
        window.NotificationUtils.showError(response.message);
      } else {
        window.NotificationUtils.showSuccess('Submition Complete')
      }

      // window.NotificationUtils.showSuccess("Details Uploaded Please verify your upload");
      // fetchdata();
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(error?.message);
      setLoading(false);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // const handleSelectChangeDistributor = (event) => {
  //   setSelectedValueDistributor(event.target.value)
  // }
console.log(selectedValue,"selectedValue");
  const renderModalContent = () => {
    return (
      <>
        {targetRows.length > 0 && (
          <>
            <DataGridContainer>
              <DataGrid
                disableSelectionOnClick={true}
                checkboxSelection={true}
                rows={targetRows}
                columns={getGridColumns()}
                // onRowSelectionModelChange={handleRowSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowIds(newRowSelectionModel);
                }}
        
                rowSelectionModel={selectedRowIds}
                density="compact"
                autoPageSize
                getRowClassName={getRowClassName}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSizeOptions={[10]}
              />
            </DataGridContainer>
            <NoteText variant="body2">
              Note: If selected, only the selected rows will be uploaded
            </NoteText>
          </>
        )}
        <InputFieldContainer>
        {/* <select onChange={handleSelectChange}>
  <option hidden>Assign To</option>
  {assignedToData &&
    assignedToData.map((ele, ind) => (
      <option key={ind} value={`${ele.name}/${ele.id}`}>
        {`${ele.name}`}
      </option>
    ))}
</select> */}
<FormControl sx={{ width: "25%" }} variant="outlined" disabled={loading}>
        <InputLabel  id="Assign-To-label">Assign To</InputLabel>
        <Select labelId="Assign-To-label" label={'Assign To'} onChange={handleSelectChange}>
          {assignedToData && assignedToData.length > 0 ? (
            assignedToData.map((ele, ind) => (
              <MenuItem key={ind} value={`${ele.name}/${ele.id}`}>
                {`${ele.name}`}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
          )}
        </Select>
      </FormControl>
          {/* <a target="_blank" href="/assets/schoolBulk.xlsx" download><Button variant="contained" color="primary">Download Template</Button></a> */}
          <Button variant="contained" color="primary"  sx={{
                      background: "primary",
                      borderColor: "#003974",
                      height:"50px",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }} onClick={handleDownload}>Download Template</Button>
          {/* <label htmlFor="filePickerButton">
            <Input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="filePickerButton"
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
            />
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              component="span"
            > */}
               <input
                    key={fileKey}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Button
                    // style={{ color: '#003974', borderColor: '#003974' }}
                    variant="contained"
                    sx={{
                      background: "primary",
                      borderColor: "#003974",
                      height:"50px",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }}
                    // startIcon={<Iconify icon="eva:attach-outline" />}
                    onClick={handleButtonClick}
                    // disabled={disableButtons}
                  >
                    Select File
                  </Button>
              {/* Select File
            </Button>
          </label> */}
        </InputFieldContainer>
        <ModalActionsContainer>
          {/* <FirstButton
            disabled={loading}
            onClick={()=>handleClose()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton> */}
          <div>

          </div>
          <Button
            disabled={loading || selectedRowIds.length === 0}
            onClick={onClickUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  const handleClose=()=>{
    onClose()
    fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add School Bulk</Typography>
          <IconButton disabled={loading} onClick={()=>handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkAddSchool;

